import { gsap, CustomEase, SteppedEase, Draggable, DrawSVGPlugin, Power2, Power1, Power0, SplitText, InertiaPlugin } from 'gsap'
gsap.registerPlugin( CustomEase, SteppedEase, DrawSVGPlugin, Draggable, InertiaPlugin, SplitText )

import * as utils from 'utils'
import * as poll from './../sections/poll'
import * as content from './../components/content'
import Question from './Question'
import { nodes } from 'nodes'
import * as palette from './../components/palette'


export default class Range extends Question {

	constructor( root_node, data ) {

		super( root_node, data )

		this.range_max = data.range_max
		this.responses = this.populate_responses()
		this.response_groups = this.populate_response_groups()


		// - - - NODES

		this.nodes.slider_wrap = utils.qs( '.slider-wrap', this.nodes.view )
		this.nodes.slider_bg = utils.qs( '.slider-wrap .bg', this.nodes.view )
		this.nodes.slider_fill = utils.qs( '.slider-wrap .fill', this.nodes.view )
		this.nodes.slider_handle = utils.qs( '.slider-wrap .handle', this.nodes.view )

		this.nodes.slider_bounds = utils.qs( '.bounds-wrap', this.nodes.view )
		this.nodes.slider_input = utils.qs( '.response-slider', this.nodes.view )
		this.nodes.slider_label = utils.qs( '.response-label', this.nodes.view )
		this.nodes.labels = {}
		if ( this.location === 'GLOBAL' ) {
			[].concat( content.locs, [ 'SUMMARY' ] ).forEach( l => {
				this.nodes.labels[ l ] = utils.qsa( `.labels-wrap .${ l } .label`, this.nodes.view )
			} )
		}
		else {
			this.nodes.labels[ 'SUMMARY' ] = utils.qsa( `.labels-wrap .SUMMARY .label`, this.nodes.view )
		}
		this.nodes.all_labels = []
		for ( let loc in this.nodes.labels ) {
			this.nodes.all_labels = this.nodes.all_labels.concat( this.nodes.labels[ loc ] )
		}

		this.nodes.data_bars = utils.qsa( '.bar-data', this.nodes.view )

		this.test_cache()
		

		// - - - LISTENERS

		// this.nodes.slider_input.addEventListener( 'input', ( e ) => { this.range_handler( e ) } )


	}


	populate_responses() {
		let arr = []
		if ( this.range_max === 100 ) {
			for( let i=0; i<=100; i++ ) { arr.push( String( i +'%' ) ) }
		}
		else {
			for( let i=1; i<=10; i++ ) { arr.push( String( i ) ) }
		}
		return arr
	}


	populate_response_groups() {
		let arr = []
		if ( this.range_max === 100 ) {
			for( let i=0; i<=9; i++ ) { arr.push( String( i * 10 ) + '% +' ) }
		}
		else {
			for( let i=1; i<=10; i++ ) { arr.push( String( i ) ) }
		}
		return arr
	}


	build_slider() {

		if ( !this.visible ) return

		let x_min = this.nodes.slider_handle.offsetWidth * -0.5
		let x_max = this.nodes.slider_wrap.offsetWidth + -2.5 * x_min

		this.slider_x_px = 0
		this.slider_x_norm = 0

		if ( this._draggable ) {
			this.get_slider_position_from_response()
			this._draggable[ 0 ].kill()
			this._draggable[ 0 ] = null
			this._draggable = null
		}
		else {
			this.slider_x_norm = 0.5
			this.slider_x_px = this.nodes.slider_wrap.offsetWidth * 0.5
		}

		gsap.set( 
			this.nodes.slider_handle,
			{ x: this.slider_x_px }
		)

		this._draggable = Draggable.create(
			this.nodes.slider_handle,
			{
				type: "x",
				trigger: this.nodes.slider_wrap,
				bounds: {
					left: x_min,
					width: x_max 
				},
				inertia: true,
				onPress: () => {

					let rect = this.nodes.slider_wrap.getBoundingClientRect()
					let x = this._draggable[ 0 ].pointerX - rect.left

					gsap.set( 
						this._draggable[ 0 ].target, 
						{ x: x }
					)
					this._draggable[ 0 ].update()
					this.get_slider_position()
					this.sync_slider_ui()

				},
				onMove: () => {
					this.get_slider_position()
					this.sync_slider_ui()
				},
				onThrowUpdate: () => {
					this.get_slider_position()
					this.sync_slider_ui()
				},
				edgeResistance: 1,
				throwResistance: 3000
			}
		)

		this._draggable[ 0 ].applyBounds()

		this._draggable[ 0 ].update()

		this.get_slider_position()
		this.sync_slider_ui( true )

		// console.log( this.slider_x_px )



	}

	get_slider_position() {

		if ( this._draggable ) {
			let x = this._draggable[ 0 ].x
			let min_x = this._draggable[ 0 ].minX
			let max_x = this._draggable[ 0 ].maxX
			let norm_x = ( x - min_x ) / ( max_x - min_x )
			this.slider_x_px = x
			this.slider_x_norm = norm_x
		}

	}

	get_slider_position_from_response() {

		if ( this._draggable && this.response_index !== null ) {
			
			let norm_x = this.range_max === 100 
				? this.response_index / this.range_max
				: ( this.response_index / ( this.range_max - 1 ) )

			this.slider_x_px = this.nodes.slider_wrap.offsetWidth * norm_x
			this.slider_x_norm = norm_x

		}
		else {

			this.slider_x_norm = 0.5
			this.slider_x_px = this.nodes.slider_wrap.offsetWidth * 0.5
		
		}

	}

	sync_slider_ui( block_paint ) {

		gsap.set(
			this.nodes.slider_label,
			{ x: this.slider_x_px }
		)

		gsap.set(
			this.nodes.slider_fill,
			{ 
				scaleX: this.slider_x_norm,
				transformOrigin: "0% 50%"
			}
		)

		let value =  Math.round( this.slider_x_norm * 100 )
		let i = 0

		if ( this.range_max === 10 ) {
			// mix to 0-9
			i = Math.round( utils.mix( value / 100, 0, 9 )  )
		}
		else {
			// already 0-100
			i = value
		}


		if ( !block_paint ) {

			this.select_response( i )

			let c = palette.get( i, 'light', this.type, this.range_max )

			gsap.set(
				this.nodes.slider_fill,
				{ backgroundColor: c }
			)

			gsap.set(
				this.nodes.slider_handle,
				{ borderColor: c }
			)

		}

		this.populate_range_label()

	}


	populate_range_label() {

		if ( 
			this.response_index !== null && 
			this.response_index >= 0 && 
			this.responses.length > this.response_index
		) {
			this.nodes.slider_label.innerHTML = this.responses[ this.response_index ]
		}
		else {
			// console.log( 'Can not populate range input', this.response_index )

			let i =  ( this.range_max === 100 )
				? 50
				: 4

			this.nodes.slider_label.innerHTML = this.responses[ i ]	
		}

	}


	select_response( index ) {

		if ( !( index >= 0 && index < this.responses.length ) ) {
			console.log( 'Error: cannot select, index out of range', index )
			return
		}

		this.response_index = index

		poll.sync_submit_button()

	}


	reset_response( force ) {

		if ( force || ( this.response_index !== null && !this.submitted ) ) {

			// this.nodes.slider_input.value = 50

			this.response_index = null

			let i =  ( this.range_max === 100 )
				? 50
				: 4
			
			this.nodes.slider_label.innerHTML = this.responses[ i ]

			poll.sync_submit_button()

			if ( this._draggable ) {
				this._draggable[ 0 ].kill()
				this._draggable[ 0 ] = null
				this._draggable = null
			}

			gsap.set(
				this.nodes.slider_fill,
				{ clearProps: 'backgroundColor' }
			)

			gsap.set(
				this.nodes.slider_handle,
				{ clearProps: 'borderColor' }
			)

		}

	}


	reset_dataviz() {

		this.loc_in_view = 'SUMMARY'

		// this.apply_loc_view_change()

	}


	update_dataviz() {

		if ( this.location === 'GLOBAL' ) {

			for ( let loc in this.data ) {
				let d = this.data[ loc ]
				d.totals.forEach( ( t, i ) => {
					if ( this.nodes.labels[ loc ][ i ] ) {
						this.nodes.labels[ loc ][ i ].innerHTML = 
								`${ Math.round( t ) } Votes`
					}
					else {
						console.error( 'Range: Missing label div, or data array is too long' )
					}
				} )
			}

		}
		else {

			let loc = 'SUMMARY'
			let d = this.data[ loc ]
			console.log( d, this.nodes.labels[ loc ] )

			d.totals.forEach( ( t, i ) => {
				if ( this.nodes.labels[ loc ][ i ] ) {
					this.nodes.labels[ loc ][ i ].innerHTML = 
							`${ Math.round( t ) } Votes`
				}
				else {
					console.error( 'Range: Missing label div, or data array is too long' )
				}
			} )

		}
		

		this.tween_updated_data()

	}


	resize() {
			
		this.build_slider()

	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - UPDATE LOOP

	update() {

		gsap.set( 
			[ this.bg_rings.dark.node, this.bg_rings.light.node, this.fg_rings.main.node ], 
			{ x: poll.submit_position_cache.x, y: poll.submit_position_cache.y }
		)

		this.bg_rings.dark.update()
		this.bg_rings.light.update()
		this.fg_rings.main.update()

	}





	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - HELPERS

	split_question() {

		if ( this.question_split ) this.question_split.revert()

		this.question_split = new SplitText( 
			this.nodes.question, 
			{ type: 'lines', linesClass: 'line' } 
		)

		this.question_split.lines.forEach( line => {
			// console.log( line )
			line.innerHTML = `<div class="line-inner">${ line.innerHTML }</div>`
			line.style.overflow = 'hidden'
		} )

		this.question_split_inners = utils.qsa( '.question .line-inner', this.nodes.view )

	}

	split_response() {

		if ( this.response_split ) this.response_split.revert()

		this.response_split = new SplitText( 
			this.nodes.submit_value, 
			{ type: 'lines', linesClass: 'line' } 
		)

		this.response_split.lines.forEach( line => {
			line.innerHTML = `<div class="line-inner">${ line.innerHTML }</div>`
			line.style.overflow = 'hidden'
		} )

		this.response_split_inners = utils.qsa( '.submit-value .line-inner', this.nodes.view )

	}



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  TWEENS


	async tween_in() {

		await new Promise( resolve => {

			this.tween_in_start()

			this.build_slider()

			gsap.set( this.nodes.view, { opacity: 0 } )


			this._tween_in_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					resolve()
					if ( this.question_split ) this.question_split.revert()
					this.tween_in_complete()
					if ( this.submitted ) {
						this._show_next_button_timer = setTimeout( poll.show_next_button, 5 * 1000 )
					}
				},
				onStart: () => {

				}
			} )

			gsap.set(
				this.nodes.all_labels,
				{ opacity: 0 }
			)

			// Split text for question
			this.split_question()

			// TO DATA view
			if ( this.submitted ) {


				let max_total = 0
				this.data[ this.loc_in_view ].totals.forEach( t => {
					max_total = max_total < t ? t : max_total
				} )

				let d = 0.05

				this.nodes.data_bars.forEach( ( b, i ) => {

					let c = palette.get( i, 'light', this.type )
					gsap.set( 
						b, 
						{ 
							backgroundColor: c, 
							scaleX: 0,
							transformOrigin: "0% 50%"
						}
					)
					
					let t = this.data[ this.loc_in_view ].totals[ i ]
					t = ( t / max_total ) * 0.9

					this._tween_in_tl.add( gsap.to(
						this.nodes.data_bars[ i ],
						0.5,
						{
							scaleX: t,
							transformOrigin: "0% 50%"
						}
					), 0.25 + i * d )

				} )

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.view,
					0.25,
					{
						opacity: 0,
					},
					{
						opacity: 1
					}
				), 0 )

				this._tween_in_tl.add( gsap.to(
					this.nodes.labels[ this.loc_in_view ],
					0.25,
					{
						opacity: 1
					}
				), 0 )

				gsap.set( 
					this.nodes.question_small_a_wrap,
					{ opacity: 1 }
				)

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.question_small,
					0.25,
					{
						opacity: 0,
					},
					{
						opacity: 1
					}
				), 0.0 )

				gsap.set( this.bg_rings.dark.node, { scale: 1, opacity: 0, transformOrigin: "50% 50%" } )

				this._tween_in_tl.add( gsap.to(
					this.bg_rings.dark.node,
					0.5,
					{
						opacity: 1
					}
				), 0 )

			}

			// TO RESPONSE view
			else {

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.view,
					0.1,
					{
						opacity: 0,
					},
					{
						opacity: 1
					}
				), 0 )	

				this._tween_in_tl.add( gsap.fromTo(
					this.question_split_inners,
					0.4,
					{
						y: "+=100%"
					},
					{
						y: "0%",
						ease: Power2.easeOut,
						stagger: 0.15
					}

				), 0.4 )

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.loc_eyebrow,
					0.2,
					{
						opacity: 0,
						y: 10
					},
					{
						opacity: 0.5,
						y: 0,
						ease: Power2.easeOut
					}
				), 0.7 )

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.divider,
					0.3,
					{
						scaleX: 0
					},
					{
						scaleX: 1,
						transformOrigin: "0% 50%",
						ease: this.wipe_ease
					}

				), 0.2 )



				this._tween_in_tl.add( gsap.fromTo(
					[ 
						this.nodes.slider_wrap,
						this.nodes.slider_label,
						this.nodes.slider_bounds
					],
					0.3,
					{
						opacity: 0,
					},
					{
						opacity: 1,
						stagger: 0.05
					}
				), 0.8 )


			}

			this._tween_in_tl.play()

		} )

	}


	async tween_out() {
		
		await new Promise( resolve => {

			this._tween_out_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					resolve()
					if ( this.question_split ) this.question_split.revert()
					this.tween_out_complete()
				},
				onStart: () => {
					this.tween_out_start()
				}
			} )

			// FROM DATA view
			if ( this.submitted ) {

				this._tween_out_tl.add( gsap.fromTo(
					this.nodes.view,
					0.25,
					{
						opacity: 1,
					},
					{
						opacity: 0
					}
				), 0 )

				this._tween_out_tl.add( gsap.to(
					this.bg_rings.dark.node,
					0.5,
					{
						opacity: 0
					}
				), 0 )

			}

			// FROM RESPONSE view
			else {

				this._tween_out_tl.add( gsap.fromTo(
					this.nodes.view,
					0.25,
					{
						opacity: 1,
					},
					{
						opacity: 0
					}
				), 0 )	

			}

			this._tween_out_tl.play()

		} )

	}




	async tween_submit() {

		await new Promise( resolve => {

			gsap.set( this.nodes.submit_wrap, { opacity: 0 } )
		
			this.nodes.submit_wrap.classList.add( 'visible' )

			this.split_response()

			this._submit_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					this.nodes.response_wrap.classList.remove( 'visible' )
					this._submit_tl.kill()
					this._submit_tl = null
					resolve()
				},
				onStart: () => {
					this.set_interactive( false )
				}
			} )

			this._submit_tl.add( gsap.fromTo(
				this.nodes.response_wrap,
				0.25,
				{
					opacity: 1,
				},
				{
					opacity: 0
				}
			), 0 )

			this._submit_tl.add( gsap.fromTo(
				this.bg_rings.dark.node,
				2.0,
				{
					scale: 0,
				},
				{
					scale: 1,
					transformOrigin: "50% 50%",
					ease: this.wipe_ease
				}
			), 0 )

			this._submit_tl.add( gsap.fromTo(
				this.bg_rings.light.node,
				1.75,
				{
					scale: 0,
					opacity: 1,
				},
				{
					opacity: 0.0,
					scale: 0.75,
					transformOrigin: "50% 50%",
					ease: this.wipe_ease
				}
			), 0.2 )

			this._submit_tl.add( gsap.fromTo(
				this.fg_rings.main.node,
				1.75,
				{
					scale: 0,
					opacity: 1,
				},
				{
					opacity: 0.0,
					scale: 1.0,
					transformOrigin: "50% 50%",
					ease: this.wipe_ease
				}
			), 0.4 )

			this._submit_tl.add( gsap.fromTo(
				this.nodes.submit_wrap,
				0.15,
				{
					opacity: 0,
				},
				{
					opacity: 1
				}
			), 0.25 )

			this._submit_tl.add( gsap.fromTo(
				[ this.nodes.submit_label_inner ].concat( this.response_split_inners ),
				0.4,
				{
					y: "+=100%"
				},
				{
					y: "0%",
					ease: Power2.easeOut,
					stagger: 0.15
				}
			), 0.25 )

			this._submit_tl.add( gsap.fromTo(
				this.nodes.question_small,
				0.25,
				{
					opacity: 0,
				},
				{
					opacity: 1
				}
			), 0.25 )

			gsap.set( 
				this.nodes.question_small_a_wrap,
				{ opacity: 0 }
			)

			this._submit_tl.play()

		} )

	}


	async tween_data() {

		await new Promise( resolve => {

			this._data_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					this.nodes.submit_wrap.classList.remove( 'visible' )
					this._data_tl.kill()
					this._data_tl = null
					this.set_interactive( true )
					this._show_next_button_timer = setTimeout( poll.show_next_button, 5 * 1000 )
					resolve()
				},
				onStart: () => {
					this.nodes.data_wrap.classList.add( 'visible' )
				}
			} )

			let max_total = 0
			this.data[ this.loc_in_view ].totals.forEach( t => {
				max_total = max_total < t ? t : max_total
			} )


			let d = 0.05

			this.nodes.data_bars.forEach( ( b, i ) => {

				let c = palette.get( i, 'light', this.type )
				gsap.set( 
					b, 
					{ 
						backgroundColor: c, 
						scaleX: 0,
						transformOrigin: "0% 50%"
					}
				)
				
				let t = this.data[ this.loc_in_view ].totals[ i ]
				t = ( t / max_total ) * 0.9

				this._data_tl.add( gsap.to(
					this.nodes.data_bars[ i ],
					0.5,
					{
						scaleX: t,
						transformOrigin: "0% 50%"
					}
				), 0.25 + i * d )

			} )

			this._data_tl.add( gsap.fromTo(
				this.nodes.submit_wrap,
				0.25,
				{
					opacity: 1,
				},
				{
					opacity: 0
				}
			), 0.0 )

			this._data_tl.add( gsap.fromTo(
				this.nodes.data_wrap,
				0.25,
				{
					opacity: 0,
				},
				{
					opacity: 1
				}
			), 0.25 )

			this._data_tl.add( gsap.to(
				this.nodes.labels[ this.loc_in_view ],
				0.25,
				{
					opacity: 1
				}
			), 0.25 )

			this._data_tl.add( gsap.fromTo(
				this.nodes.question_small_a_wrap,
				0.25,
				{
					opacity: 0,
				},
				{
					opacity: 1
				}
			), 0.25 )


			this._data_tl.play()

		} )

	}


	to_loc_chain() {

		if ( this.to_loc_chain_dirty ) {

			this._loc_tl.pause()
			this._loc_tl.kill()
			this._loc_tl = null

			this.tween_loc( this.to_loc_chain_dirty )

			this.to_loc_chain_dirty = false

		}

	}


	async tween_loc( loc ) {

		await new Promise( resolve => {

			if ( this._loc_tl ) {
				this.to_loc_chain_dirty = loc
				return
			}


			if ( !(
				this.nodes.labels[ this.loc_in_view ] && 
				this.nodes.labels[ this.loc_in_view ].length > 0  && 
				this.nodes.labels[ loc ] && 
				this.nodes.labels[ loc ].length > 0
			) ) {
				console.log( 'Error: cannot tween to or from invalid location slugs', this.loc_in_view, loc, this.nodes.labels )
				return
			}

			if ( loc === this.loc_in_view ) {
				return
			}


			this._loc_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					if ( this.to_loc_chain_dirty ) {

						this.to_loc_chain()

					}
					else {
						resolve()
						this._loc_tl.pause()
						this._loc_tl.kill()
						this._loc_tl = null
					}
				},
				onStart: () => {

				}
			} )


			let max_total = 0
			this.data[ loc ].totals.forEach( t => {
				max_total = max_total < t ? t : max_total
			} )

			this.nodes.data_bars.forEach( ( b, i ) => {

				let t = this.data[ loc ].totals[ i ]
				t = ( t / max_total ) * 0.9

				this._loc_tl.add( gsap.to(
					this.nodes.data_bars[ i ],
					0.5,
					{
						scaleX: t,
						transformOrigin: "0% 50%"
					}
				), 0.0 )

			} )

			this._loc_tl.add( gsap.to(
				this.nodes.labels[ this.loc_in_view ],
				0.25,
				{ opacity: 0 }
			), 0 )

			this._loc_tl.add( gsap.to(
				this.nodes.labels[ loc ],
				0.25,
				{ opacity: 1 }
			), 0.25 )

			this.loc_in_view = loc

			this._loc_tl.play()

		} )

	}



	tween_updated_data() {

		if ( !this.submitted && !this.visible ) {
			return
		}

		if ( this._data_update_tl ) {
			this._data_update_tl.pause()
			this._data_update_tl.kill()
			this._data_update_tl = null
		}

		this._data_update_tl = gsap.timeline( {
			paused: true,
			onComplete: () => {

			},
			onStart: () => {

			}
		} )


		let max_total = 0
		this.data[ this.loc_in_view ].totals.forEach( t => {
			max_total = max_total < t ? t : max_total
		} )

		this.nodes.data_bars.forEach( ( b, i ) => {

			let t = this.data[ this.loc_in_view ].totals[ i ]
			t = ( t / max_total ) * 0.9

			this._data_update_tl.add( gsap.to(
				this.nodes.data_bars[ i ],
				0.25,
				{
					scaleX: t,
					transformOrigin: "0% 50%"
				}
			), 0.0 )

		} )		

		this._data_update_tl.play()

	}


}