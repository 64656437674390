import { gsap, CustomEase, SteppedEase, DrawSVGPlugin, Power2, Power1, Power0 } from 'gsap'
gsap.registerPlugin( CustomEase, SteppedEase, DrawSVGPlugin )

import * as utils from 'utils'
import View from './View'
import { nodes } from 'nodes'
import * as poll from './../sections/poll'
import * as world from './../sections/world'
import * as content from './../components/content'
import * as controller from './../app/controller'
import * as palette from './../components/palette'
import * as navigation from './../components/navigation'

import * as svg from './../model/svg'


export default class Beacon extends View {

	constructor( root_node, data ) {

		super( root_node, data )

		this.id = 'beacon'

		this.nodes.svg = utils.qs( '.beacon-wrap svg', this.nodes.view )
		this.nodes.root = utils.qs( '.beacon-wrap svg .root', this.nodes.view )
		this.nodes.pin_wrap = utils.qs( '.loc-pin-wrap', this.nodes.view )
		this.nodes.pin_label = utils.qs( '.loc-pin-wrap .label', this.nodes.view )
		this.nodes.pin = utils.qs( '.loc-pin-wrap .pin', this.nodes.view )

		this.nodes.cta_1 = utils.qs( '.cta-wrap .cta-1', this.nodes.view )
		this.nodes.cta_2 = utils.qs( '.cta-wrap .cta-2', this.nodes.view )
		this.nodes.link = utils.qs( '.link-wrap', this.nodes.view )
		this.nodes.link_list = utils.qs( '.list-link', this.nodes.view )
		this.nodes.link_a = utils.qs( '.link-wrap a', this.nodes.view )

		this.ring_draw_ease = CustomEase.create( 'custom', 'M0,0,C0.3,0,0.2,1,1,1' )
		this.ring_spin_ease = CustomEase.create( 'custom', 'M0,0,C0.2,0,0.8,1,1,1' )
		this.circ_wipe_ease = CustomEase.create("custom", "M0,0,C0.2,0,0.1,1,1,1")

		this.nodes.link_list.addEventListener( 'click', () => { this.link_list_handler() } )

		this.nodes.link_a.addEventListener( 'click', () => { controller.session.cta() } )

		this.circles_bg = []
		this.circles_top = []
		this.scale_factor = 1

	}


	sync_beacon() {

		// CLEAN & reset

		this.circles_bg.forEach( c => {
			c.node.remove()
			c = null
		} )
		this.circles_bg = []

		this.circles_top.forEach( c => {
			c.node.remove()
			c = null
		} )
		this.circles_top = []

		this.colors = []
		this.colors_light = []

		this.rings = []
		this.rings_alt = []
		this.radii = []

		this.nodes.root.innerHTML = ''


		// CONFIG

		let r_min = 30.0
		let r_max = 90.0

		let thick_min = 3
		let thick_max = 12

		this.scale_factor = this.nodes.svg.clientWidth / 200



		// COLORS

		poll.question_modules.forEach( q => {

			if ( q.submitted ) {

				let c = palette.get( q.response_index, 'dark', q.type, q.range_max )
				this.colors.push( c )

				let cc = palette.get( q.response_index, 'light', q.type, q.range_max )
				this.colors_light.push( cc )

			}

		} )


		// VARY ring thickness based on total number

		let t = utils.mix( 1 - ( ( this.colors.length - 1 ) / 7 ), thick_min, thick_max, true )
		let r_mid = ( r_max - r_min ) * 0.5 + r_min
		let r_spread = utils.mix( ( ( this.colors.length - 1 ) / 4 ), 20, 0.5 * ( r_max - r_min ), true )

		r_min = r_mid - r_spread
		r_max = r_mid + r_spread


		// - - - CREATE each beacon pair of rings, and background pair of circles

		// bg first...
		this.colors.forEach( ( c, i ) => {

			this.create_circ_bg( c )

		} )

		// ...then toppers. rings in diff svg container
		this.colors.forEach( ( c, i ) => {
			
			let cc = this.colors_light[ i ]

			let r = ( this.colors.length === 1 )
				? r_mid
				: utils.mix( i / ( this.colors.length - 1 ), r_min, r_max )

			this.radii.push( r )

			// rings
			this.create_circ( r, c, t )
			this.create_circ_alt( r, cc, t )

		} )

		// topper
		this.colors.slice().reverse().forEach( ( c, i ) => {
		
			if ( i > 0 ) {

				let r = this.radii.slice().reverse()[ i - 1 ]

				this.create_circ_top( r, c, true )

				if ( i === this.colors.length - 1 ) {

					let r = this.radii.slice().reverse()[ i - 1 ]

					this.create_circ_top( r, c, true )
				
					this.create_circ_top( this.radii[ 0 ], "#1C2B33", false )

				}

			}
			else if ( i === 0 && this.colors.length === 1 ) {

				this.create_circ_top( this.radii[ 0 ], "#1C2B33", false )

			}

		} )

		this.circles_top.reverse()

	}


	create_circ( radius, color, thick ) {

		let c = new svg.Circle( {
			position: new svg.Vector2( 100, 100 ),
			radius: radius,
			strokeWidth: thick,
			strokeColor: color
		} )

		this.nodes.root.appendChild( c.node )

		gsap.set( c.node, { drawSVG: "0% 0%", rotate: -90, transformOrigin: "50% 50%" } )

		this.rings.push( c )

	}


	create_circ_alt( radius, color, thick ) {

		let c = new svg.Circle( {
			position: new svg.Vector2( 100, 100 ),
			radius: radius,
			strokeWidth: thick,
			strokeColor: color
		} )

		this.nodes.root.appendChild( c.node )

		gsap.set( c.node, { drawSVG: "0% 0%", rotate: -90, transformOrigin: "50% 50%" } )

		gsap.set( c.node, { opacity: 0.75 } )

		this.rings_alt.push( c )

	}


	create_circ_bg( color ) {

		let r = Math.sqrt(
			Math.pow( controller.state.window_size.w, 2.0 ) + 
			Math.pow( controller.state.window_size.h, 2.0 )
		) * 0.5

		let c = new svg.Circle( {
			radius: r,
			position: new svg.Vector2( 0, 0 ),
			fillColor: color
		} )

		this.circles_bg.push( c )

		nodes.svg_bg_beacon.appendChild( c.node )

		gsap.set( c.node, { scale: 0, transformOrigin: "50% 50%" } )

	}


	create_circ_top( radius, color, hidden ) {

		let x = poll.submit_position_cache.x
		let y = poll.submit_position_cache.y

		let c = new svg.Circle( {
			radius: radius * this.scale_factor,
			position: new svg.Vector2( 0, 0 ),
			fillColor: color
		} )

		this.circles_top.push( c )

		nodes.svg_bg_beacon.appendChild( c.node )

		if ( hidden ) {
			gsap.set( c.node, { scale: 0, transformOrigin: "50% 50%" } )
		}

	}



	resize() {
			
		if ( !this.visible ) return


		// Toppers

		this.scale_factor = this.nodes.svg.clientWidth / 200

		this.circles_top.forEach( ( c, i ) => {

			c.radius = this.radii[ Math.max( 0, i - 1 ) ] * this.scale_factor

		} )


		// full screen circle wipes

		let r = Math.sqrt(
			Math.pow( controller.state.window_size.w, 2.0 ) + 
			Math.pow( controller.state.window_size.h, 2.0 )
		) * 0.5

		this.circles_bg.forEach( c => {

			c.radius = r
		
		} )

	}



	async link_list_handler() {

		window.location.href = "/list"

		// await this.tween_out()

		// navigation.link_click( null, 'home' )


	}



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - UPDATE LOOP

	update() {

		this.circles_bg.forEach( c => {

			gsap.set( 
				c.node, 
				{ 
					x: controller.state.window_size.w * 0.5, 
					y: controller.state.window_size.h * 0.5 
				}
			)

			c.update()

		} )

		this.circles_top.forEach( c => {

			gsap.set( 
				c.node, 
				{ 
					x: controller.state.window_size.w * 0.5, 
					y: controller.state.window_size.h * 0.5 
				}
			)
			
			c.update()

		} )	

	}



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  TWEENS

	async tween_in() {
		
		poll.visiting_beacon()

		await world.hone_globe( controller.SESSION_LOCATION )

		await new Promise( resolve => {

			controller.to_list()

			gsap.set( this.nodes.view, { opacity: 0 } )

			this.tween_in_start()

			this.sync_beacon()

			if ( this._tween_in_tl ) {
				this._tween_in_tl.pause()
				this._tween_in_tl.kill()
				this._tween_in_tl = null
			}

			this._tween_in_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {

					resolve()
					
					this.tween_special()

					if ( this._tween_in_tl ) {
						this._tween_in_tl.pause()
						this._tween_in_tl.kill()
						this._tween_in_tl = null
					}

					this.tween_in_complete()

				},
				onStart: () => {
					
				}
			} )

			this._tween_in_tl.add( gsap.fromTo(

				this.nodes.view,
				0.15,
				{
					opacity: 0,
				},
				{
					opacity: 1
				}
			), 0 )


			this._tween_in_tl.add( gsap.fromTo(
				this.nodes.pin,
				0.3,
				{
					scale: 0,
					transformOrigin: "50% 50%"
				},
				{
					scale: 1,
					transformOrigin: "50% 50%",
					ease: Power2.easeInOut
				}

			), 0 )


			this._tween_in_tl.add( gsap.fromTo(
				this.nodes.pin_label,
				0.3,
				{
					opacity: 0,
					y: -10
				},
				{
					opacity: 1,
					y: -18,
					ease: Power2.easeOut
				}

			), 0.15 )


			this._tween_in_tl.add( gsap.fromTo(

				nodes.svg_bg_beacon,
				0.3,
				{
					opacity: 0
				},
				{
					opacity: 0.5
				}

			), 0 )


			let d = 0.5
			let ring_t = 0.75	// time
			let ring_o = 0.25	// overlap
			let ring_o_spin = 0.5	// overlap
			let alt_d = ring_t + 0.5

			this.rings.forEach( ( r, i ) => {

				let r_alt = this.rings_alt[ i ]

				this._tween_in_tl.add( gsap.fromTo(
					r.node,
					ring_t,
					{
						drawSVG: "0% 0%"
					},
					{
						drawSVG: "0% 100%",
						ease: this.ring_draw_ease
					}
				), d + ring_t * i - i * ring_o )


				this._tween_in_tl.add( gsap.fromTo(
					r_alt.node,
					ring_t,
					{
						drawSVG: "0% 0%"
					},
					{
						drawSVG: "0% 75%",
						ease: this.ring_draw_ease
					}
				), d + alt_d + ring_t * i - i * ring_o )



				// Spin, & loop. Do this here so its seamless per ring
				gsap.fromTo(
					r_alt.node,
					7.5,
					{
						rotate: -90,
						transformOrigin: "50% 50%"
					},
					{
						rotate: 270 + 360,
						ease: Power0.easeNone,
						repeat: -1,
						delay: d + alt_d + ring_t * i - i * ring_o
					}
				)

			} )

			let i = this.rings.length - 1
			let base = d + alt_d + ring_t * i - i * ring_o


			this._tween_in_tl.add( gsap.fromTo(
				[ this.nodes.cta_1, this.nodes.cta_2, this.nodes.link ],
				0.3,
				{
					opacity: 0,
					y: -20
				},
				{
					opacity: 1,
					y: 0,
					stagger: 0.15
				}
			), base )



			this._tween_in_tl.play()

		} )

	}


	tween_special() {


		// - - - Draw, undraw, looping

		this.rings_alt.forEach( ( r_alt ) => {

			gsap.fromTo(
				r_alt.node,
				12,
				{
					drawSVG: "0% 75%",
				},
				{
					drawSVG: "0% 5%",
					ease: Power2.easeInOut,
					repeat: -1,
					yoyo: true
				}
			)

		} )


		// - - - Wipes

		this._tween_special_tl = gsap.timeline( { 
			paused: true,
			onComplete: () => {

				if ( this._tween_special_tl ) {
					this._tween_special_tl.pause()
					this._tween_special_tl.kill()
					this._tween_special_tl = null
				}

			}
		} )


		let wipe_dur = 2.75
		let wipe_hold = -0.75

		this.circles_bg.forEach( ( c, i ) => {
			
			let bump = ( i === 0 ) ? 1 : 0

			let c_top = this.circles_top[ i + ( 1 - bump ) ]

			let start_scale = ( c_top.radius ) / c.radius

			this._tween_special_tl.add( gsap.fromTo(
				c.node,
				wipe_dur,
				{
					scale: start_scale,
					transformOrigin: "50% 50%",
				},
				{
					scale: 1.0,
					ease: this.circ_wipe_ease,
					immediateRender: false
				}
			), wipe_dur * i + wipe_hold * i )


			this._tween_special_tl.call( () => {

				gsap.set(
					c_top.node,
					{
						scale: 1,
						transformOrigin: "50% 50%"
					}
				)
			}, null, wipe_dur * ( i + bump ) + wipe_hold * i )


			if ( i !== ( this.circles_bg.length - 1 ) ) {

				this._tween_special_tl.add( gsap.to(
					c.node,
					0.5,
					{
						opacity: 0
					}
				), wipe_dur * ( i + 2 ) + wipe_hold * ( i + 1 ) )

			}

		} )


		let i = this.circles_bg.length - 1

		this._tween_special_tl.add( gsap.to(
			nodes.svg_bg_beacon,
			0.3,
			{
				opacity: 0.15
			}
		), wipe_dur * ( i + 2 ) + wipe_hold * ( i + 1 ) )



		this._tween_special_tl.play()

	}


	async tween_out() {
		
		world.spin_globe()

		await new Promise( resolve => {

			if ( this._tween_special_tl ) {
				this._tween_special_tl.pause()
				this._tween_special_tl.kill()
				this._tween_special_tl = null
			}

			this._tween_out_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					resolve()
					this.tween_out_complete()
				},
				onStart: () => {
					this.tween_out_start()
				}
			} )

			this._tween_out_tl.add( gsap.fromTo(

				this.nodes.view,
				0.25,
				{
					opacity: 1,
				},
				{
					opacity: 0
				}
			), 0 )


			this._tween_out_tl.add( gsap.to(

				nodes.svg_bg_beacon,
				0.3,
				{
					opacity: 0.0
				}

			), 0 )


			this._tween_out_tl.play()

		} )

	}



}