// import * as THREE from 'three'

import * as utils from './../components/utils'
import { nodes } from './../components/nodes'
import * as world from './../sections/world'
import * as poll from './../sections/poll'
import { all as pages } from './../components/pages'
import mobile from './../components/mobile'
import * as socket from './../components/socket'

import short_id from 'short-unique-id'
import * as async from 'async-es'


import * as debug from './../sections/debug'


export let POLL_ID = null
export let POLL_META = {}
export let SESSION_LOCATION = ''
export let is_mobile = true
export let is_safari = true





export let build = () => {

	// window.addEventListener( 'scroll', resize_handler )
	window.addEventListener( 'resize', resize_handler )
	window.addEventListener( 'orientationchange', resize_handler )
	if ( window.visualViewport ) {
		window.visualViewport.addEventListener( 'resize', resize_handler )
	}

	document.addEventListener('touchmove', function (event) {
		if (event.scale !== 1) { event.preventDefault(); }
	}, { passive: false });

	document.addEventListener('visibilitychange', function(ev) {
		
		let active = document.visibilityState === 'visible'
		session_active_handler( active )

	} )

	/*
	console.log(
		'%c state ', 'background: #D865C7; color: #fff',
		state
	)
	*/
	
	let mobile_data = mobile()
	is_mobile = mobile_data.is_mobile
	is_safari = mobile_data.is_safari


	if ( is_mobile ) {

		nodes.body.classList.add('mobile')

	}

	resize_handler()

}

export let entry_point = null
export let entry_point_initial = null

export let set_entry = ( path ) => {

	if ( path.includes( 'list' ) ) {
		to_list()
	}
	else if ( entry_point === null ) {


		entry_point_initial = ( path.startsWith('/') ) 
			? path.replaceAll('/','').replace('poll','')
			: path.replace('poll/','')

	}

	entry_point = path

}



export let to_list = () => {

	show_list = true
	// console.log( 'show list' )

}


export let show_list = false


export let set_poll = ( poll_meta ) => {

	if ( poll_meta ) {
		POLL_ID = poll_meta.id
		POLL_META = poll_meta
		// console.log( 'Set active poll to', poll_meta )
	}
	else {
		POLL_ID = null
		POLL_META = {}
		// console.log( 'Reset active poll' )
	}


}

export let get_poll = () => {
	return POLL_ID	
}

export let set_location = ( location ) => {

	SESSION_LOCATION = location

}

export let validate_poll_location = () => {

	if ( POLL_ID && POLL_META.location === 'GLOBAL' || POLL_META.location === SESSION_LOCATION ) {
		return true
	}
	else {
		return false
	}

}


let resize_update = false

let resize_debounce = null

let resize_handler = () => {

	clearTimeout( resize_debounce )
	resize_debounce = setTimeout( () => {

		if ( resize_update ) return
		resize_update = true

		requestAnimationFrame( () => {
			resize_update = false
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);

			// jank to reset iOS browser
			if ( is_safari ) {
				setTimeout( () => { window.scrollTo( 0, 1 ) }, 500 )
			}
			
			sync_window_size_state()

			world.resize_handler()

			poll.resize_handler()

			sync_device_orientation()

		} )

	}, 500 )

}


let sync_device_orientation = () => {

	let keyboard = nodes.home.code_input === document.activeElement
	let h = state.window_size.h < 450
	let landscape = ( state.window_size.w > state.window_size.h && is_mobile ) && state.window_size.h < 800

	if  ( ( h || landscape ) && !keyboard ) {
		document.documentElement.classList.add( 'no-landscape' )
	}
	else {
		document.documentElement.classList.remove( 'no-landscape' )
	}

}


export let sync_window_size_state = () => {

	let w = window.innerWidth
	let h = window.innerHeight

	state.window_size = {
		w: w,
		h: h,
		aspect: w / h,
		d: Math.sqrt( Math.pow( w, 2 ) + Math.pow( h, 2 ) ),
		px: window.devicePixelRatio,
		u_px_norm: { x: w, y: h },
		aspect_vec: { x: 1.0, y: w / h },
		x: 0,
		y: 0,
		u: 1.0 / w,
		v: 1.0 / h,
	}
	
}


export let reset_scroll = () => {

	document.documentElement.scrollTop = 0
	window.scrollTo( 0, 0 )

}


export let sync_data = ( data ) => {

}

export let  sync_active_question = ( data ) => {

}

export let  socket_reconnect = () => {

}



export let sync_body_class = ( template ) => {

	for ( let t in pages ) {
		nodes.body.classList.remove( `section-${ t }` )
	}

	nodes.body.classList.add( `loaded` )
	nodes.body.classList.add( `section-${ template }` )

}


let session_active_handler = ( visible ) => {

	if ( visible ) {
		session.resume()
	}
	else if ( poll.beacon_visited ) {
		session.complete()
		session.stop()
	}
	else {
		session.abandon()
		session.stop()
	}

}


export let session = {

	emit: ( event ) => {
	
		analytics.emit( `session_${ event }` )
	
	},

	start: 			() => { session.emit( 'start' ) },
	stop: 			() => { session.emit( 'stop' ) },
	resume: 		() => { session.emit( 'resume' ) },
	complete: 		() => { session.emit( 'complete' ) },
	abandon: 		() => { session.emit( 'abandon' ) },

	cta: 			() => { analytics.emit( 'cta_click' ) },

}


export let analytics = {

	emit: ( event ) => {

		socket.emit( 
			`web_poll/${ event }`, 
			{ session_id: state.session_id }
		)

	}

}









// SIMPLE state object for tracking certain states across the app

export let state = {

	is_mobile: false,
	
	window_size: {},

	session_id: utils.uuid()

}