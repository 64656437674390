import { parse_images } from './../app/controller'
import * as controller from './../app/controller'
import * as content from './content'
import * as utils from './utils'


export let all = { 
	loaded: false,
	GENERAL: {},
	LOCATION_POLLS: {},
	POLL: {},
	INITIAL_DATA: {},
	STATIC: {
		NEXT: "Next",
		BACK: "Back",
		SUMMARY: "ALL",
		WELCOME: "Help us grow a healthy community by lending your perspective.",
		LOCATION_SELECT: "Please select the closest Partner&nbsp;Center&nbsp;location.",
		LOCATION_LABEL: "Select a location",
		BEGIN_BUTTON: "Let’s get started",
		ENTER_CODE: "Enter the code for your poll",
		CHANGE_LOCATION: "Change location",
		CHOOSE_POLL: "Choose a Poll",
		BEGIN_POLL: "Begin this poll",
		EMPTY_LOC_A: "Uh oh!",
		EMPTY_LOC_B: "There are no active polls for this location.",
		CTA: "Thank you for your perspective!",
		FB_GROUP: "Continue to Facebook",
		FB_GROUP_OVERRIDE: "Go to Discussion",
		FIRST_RESPONDER: "Thanks for being one of the first to answer. Check back later to see how things stack up!",
		YOUR_RESPONSE: "Your Response",
		BINARY_FRAG_A: "people in",
		BINARY_FRAG_B: "said&nbsp;",
		BINARY_FRAG_C: "people",
	}
}

export let locs = []

// - - - - - - -  - - - - - - - - - - - - - - - - - - ORGANIZE incoming content

export let store_general = ( data, callback ) => {

	all.GENERAL = utils.copy_obj( data )

	locs = Object.keys( all.GENERAL.LOCATIONS )

	finish_store()

	callback()

}

export let store_location_polls = ( data ) => {

	all.LOCATION_POLLS = utils.copy_obj( data )

}

export let store_poll_content = ( data ) => {

	all.POLL = utils.copy_obj( data )


	let loc = controller.SESSION_LOCATION

	if ( all.POLL.generic_cta_and_url ) {
		all.POLL.cta = all.GENERAL.LOCATIONS[ loc ].GENERIC_CTA
	}
	else if ( all.POLL.custom_cta && all.POLL.custom_cta !== '' ) {
		all.POLL.cta = all.POLL.custom_cta
	}
	else {
		all.POLL.cta = 'hidden'
	}


	if ( all.POLL.generic_cta_and_url ) {
		all.POLL.url = all.GENERAL.LOCATIONS[ loc ].GENERIC_URL
	}
	else if ( all.POLL.custom_url && all.POLL.custom_url !== '' ) {
		all.POLL.url = all.POLL.custom_url
	}
	else {
		all.POLL.url = 'hidden'
	}


	all.POLL.locations_master = locs
	all.POLL.locations = all.GENERAL.LOCATIONS
	all.POLL.locations.SUMMARY = {
		name: 'the world'
	}
	
	all.POLL.SESSION_LOCATION = controller.SESSION_LOCATION

	all.POLL.QUESTIONS.forEach( ( q, i ) => {

		if ( q.type === 'RANGE' ) {
			all.POLL.QUESTIONS[ i ].response_groups = 
				populate_range_response_groups( q.range_max )
		}

	} )

	all.POLL.STATIC = all.STATIC

}


export let store_poll_data = ( data ) => {

	let temp = utils.copy_obj( data.data )
	let output = {}
	for ( let qid in temp ) {
		output[ qid ] = temp[ qid ].data
	}
	all.INITIAL_DATA = output
	// console.log( data )

}


let finish_store = () => {

	all.loaded = true

	if ( true ) {

		/*
		console.log( 
			'%c content ', 'background: #06C7B3; color: #fff', 
			all
		)
		*/

	}

}

let populate_range_response_groups = ( range_max ) => {

	let arr = []
	if ( range_max === 100 ) {
		for( let i=0; i<=9; i++ ) { arr.push( String( i * 10 ) + '% +' ) }
	}
	else {
		for( let i=1; i<=10; i++ ) { arr.push( String( i ) ) }
	}
	return arr

}