import * as io from 'socket.io-client'
import fetch from 'node-fetch'

import * as controller from './../app/controller'
import * as home from './../sections/home'
import * as content from './content'
import * as poll from './../sections/poll'

export let socket
export let test_socket

let status = {
	connected: false,
	ever_connected: false
}

export let build = async () => {

	await new Promise( ( resolve, reject ) => { 

		socket = io.connect( process.env.CMS_HOST )
		// socket = io.connect( 'https://beacons.hush.nyc/' )

		socket.on( 'refresh', () => {
			// console.log('refresh')
			window.location.reload()
		})

		socket.on( 'handshake', () => {
			socket.emit( 'handshake', { session_id: controller.state.session_id, type: 'web' } )
		})

		socket.on( 'welcome', async ( data ) => {

			status.connected = true
			// console.log( '%c socket ', 'background: #3793cf; color: #fff', { connected: status.connected, as: data } )
			
			if ( !status.ever_connected ) {
				controller.session.start()
			}

			controller.set_entry( window.location.pathname )

			if ( status.ever_connected ) {
				controller.socket_reconnect()
			}
			status.ever_connected = true

			/*
			let url = process.env.CMS_HOST + process.env.CMS_POLL_EXISTS_API
			let body = JSON.stringify( { poll_id: controller.entry_point_initial, location: controller.SESSION_LOCATION } )
			console.log( body )
			let response = await fetch( 
				url, 
				{ 
					method: 'POST', 
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: body
				}
			)

			let json = await response.json()

			if ( json.valid === true ) {
				controller.set_poll( json )
			}
			*/

			resolve()

		} )


		socket.on( 'disconnect', ( data ) => {
			// console.log( '%c socket ', 'background: #d63845; color: #fff', { connected: status.connected } )
		} )

		socket.on( 'data_sync', ( data ) => {

			poll.sync_data( data )
			
		} )

	} )

}


export let emit = (event, data) => {
	socket.emit(event, data)
}