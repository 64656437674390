import * as pages from './../components/pages'



export default class Page {

	constructor( data ) {

		this.path = data.path

		this.instance = data.instance

		this.template = data.template

		this.get_data = data.get_data

		this.on_load = data.on_load


		pages.add( this )

	}

}