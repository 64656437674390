import Vector2 from './Vector2'
import world from './../../sections/world'

export default class Rectangle {

	constructor( data ) {

		// POSITION
		this._position = ( data.position && data.position.isVector2 ) 
			? data.position
			: new Vector2( 0, 0 )

		// SCALE
		this._scale = ( data.scale && data.scale.isVector2 )
			? data.scale
			: new Vector2( 10, 10 )

		// BOUNDING BOX overrides
		if ( data.position === undefined && data.scale === undefined && data.boundingBox && 
			this.boundingBox.x !== undefined &&
			this.boundingBox.y !== undefined &&
			this.boundingBox.w !== undefined &&
			this.boundingBox.h !== undefined
		) {
			this._position = new Vector2( 
				data.boundingBox.x + ( data.boundingBox.w * 0.5 ),
				data.boundingBox.y + ( data.boundingBox.h * 0.5 ),
			)
			this._scale = new Vector2( 
				( data.boundingBox.w ),
				( data.boundingBox.h )
			)
		}

		// STROKE WIDTH
		this._strokeWidth = ( 
			data.strokeWidth !== undefined && 
			typeof data.strokeWidth === 'number'
		)
			? data.strokeWidth
			: 0

		// FILL COLOR
		this._fillColor = ( data.fillColor !== undefined )
			? data.fillColor
			: "#000"

		// STROKE COLOR
		this._strokeColor = ( data.strokeColor !== undefined )
			? data.strokeColor
			: "#000"

		// STROKE JOIN
		this._strokeJoin = ( data.strokeJoin === 'round' || data.strokeJoin === 'bevel' )
			? data.strokeJoin
			: 'none'


		// DOM NODE
		this._node = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'rect'
		)

		// APPLY INTERNALS TO NODE
		this.update()

	}


	// - - - GETTERS

	get position() 		{ return this._position }

	get x() 			{ return this._position.x }

	get y() 			{ return this._position.y }	

	get scale() 		{ return this._scale }

	get strokeWidth() 	{ return this._strokeWidth }

	get fillColor() 	{ return this._fillColor }

	get strokeColor() 	{ return this._strokeColor }

	get node() 			{ return this._node }

	get boundingBox() { 
		return {
			x: this._x,
			y: this._y,
			w: this._w,
			h: this._h
		}
	}



	// - - - SETTERS

	set position( val ) {
		this._position.x = val.x
		this._position.y = val.y
	}

	set scale( val ) {
		this._scale.x = val.x
		this._scale.y = val.y
	}


	// - - - HELPERS

	update() {

		this.updateBoundingBox()

		this._node.setAttribute( 'x', this._x )
		this._node.setAttribute( 'y', this._y )
		this._node.setAttribute( 'width', this._w )
		this._node.setAttribute( 'height', this._h )

		this._node.setAttribute( 'fill', this._fillColor )
		this._node.setAttribute( 'stroke', this._strokeColor )
		this._node.setAttribute( 'stroke-width', this._strokeWidth )
		this._node.setAttribute( 'stroke-linejoin', this._strokeJoin )
	}
	
	updateBoundingBox() {
		this._x = this._position.x - 0.5 * this._scale.x
		this._y = this._position.y - 0.5 * this._scale.y
		this._w = this._scale.x
		this._h = this._scale.y
	}

	destroy() {
		this._node.remove()
	}

}
