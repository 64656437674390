import { gsap, CustomEase, SteppedEase, DrawSVGPlugin, Power2, Power1, Power0 } from 'gsap'
gsap.registerPlugin( CustomEase, SteppedEase, DrawSVGPlugin )

import * as svg from './../model/svg'

import { nodes } from './../components/nodes'
import * as utils from './../components/utils'
import { state, sync_window_size_state } from './../app/controller'
import * as controller from './../app/controller'
import { all as content } from './../components/content'
import colors from './../components/colors'
import * as poll from './../sections/poll'

let active = true
let objects = {}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SVG WORLD


export let build_world = () => {

	gsap.ticker.add( update )
	
	
	resize_handler()

	build_scene()

	spin_globe()

	document.addEventListener( 'mousemove', mouse_move, { passive: true } )
	document.addEventListener( 'drag', mouse_move, { passive: true } )



	// setTimeout( () => {

	// hone_globe( 'NYC' )

	// }, 5 * 1000 )

}


let build_scene = () => {

	// Marker at center of canvas
	objects.center = new svg.Rectangle( { 
		position: new svg.Vector2( state.window_size.w * 0.5, state.window_size.h * 0.5 ),
		scale: new svg.Vector2( 1, 1 ),
		fillColor: "#00FFFF"
	} )
	// nodes.svg_fg_root.appendChild( objects.center.node )

	// Marker at center of canvas
	objects.circle_test = new svg.Circle( { 
		position: new svg.Vector2( state.window_size.w * 0.5, state.window_size.h * 0.5 ),
		radius: state.window_size.d * 0.5,
		fillColor: colors.bg.base
	} )
	nodes.svg_bg_root.appendChild( objects.circle_test.node )




}


export let mouse = {
	norm: { x: 0, y: 0 },
	px: { x: 0, y: 0 }
}

let mouse_cache = {
	norm: { x: 0, y: 0 },
	px: { x: 0, y: 0 }
}



let mouse_move = ( e ) => {

	let m = {
		x: 0,
		y: 0
	}

	if ( e.clientX !== undefined && e.clientY !== undefined ) {
		m.x = e.clientX
		m.y = e.clientY
	}
	else if ( e.x !== undefined && e.y !== undefined ) {
		m.x = e.x + state.window_size.x
		m.y = e.y + state.window_size.y
	}
	else {
		console.error( 'bad mousemove input:', e )
	}

	// PIXEL coords limited to webgl canvas
	mouse.px.x = utils.clamp( m.x - state.window_size.x, 0, state.window_size.w )
	mouse.px.y = utils.clamp( m.y - state.window_size.y, 0, state.window_size.h )

	// NORMALIZED, -1 to 1 in both dimensions
	mouse.norm.x =  1 * ( ( m.x - state.window_size.x ) / state.window_size.w * 2 - 1 )
	mouse.norm.y = -1 * ( ( m.y - state.window_size.y ) / state.window_size.h * 2 - 1 )

	// CLAMP, only after testing for mouse.hover_world
	mouse.norm.x = utils.clamp( mouse.norm.x, -1, 1 )
	mouse.norm.y = utils.clamp( mouse.norm.y, -1, 1 )

	mouse_moved = true

}

let mouse_moved = false



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  UPDATE HANDLERS

let update = ( time, deltaTime, frame ) => {

	if ( !active ) return

	if ( objects.center ) objects.center.update()
	if ( objects.circle_test ) objects.circle_test.update()

	if( poll.status.init ) poll.update()

}


let zoom = 1.0

let world_tex_asset = {
	x: 4096,
	y: 2048
}

let world_tex_scale = 1.0

export let resize_handler = () => {

	// update svg canvas
	nodes.svg_fg.setAttribute("viewBox", `0 0 ${ state.window_size.w } ${ state.window_size.h }` )
	nodes.svg_bg.setAttribute("viewBox", `0 0 ${ state.window_size.w } ${ state.window_size.h }` )

	// update background world texture scale


	let ratio = ( world_tex_asset.x / world_tex_asset.y )

	// FILL WIDTH, TRIM HEIGHT
	if ( state.window_size.aspect >= ratio ) {
		world_tex_scale = 1.0 * zoom
		gsap.set( nodes.world_wrap, { backgroundSize: String( 100 * world_tex_scale ) + "%" } )
	}	
	// FILL HEIGHT, TRIM WIDTH
	else {
		world_tex_scale = ratio / state.window_size.aspect * zoom
		gsap.set( nodes.world_wrap, { backgroundSize: String( 100 * world_tex_scale ) + "%" } )
	}
	
	debounce_timeline_reset()

	// RECENTER marker
	if ( objects.center ) {
		objects.center.position.x = state.window_size.w * 0.5
		objects.center.position.y = state.window_size.h * 0.5
	}

	// RECENTER beacon group
	if ( objects.circle_test ) {
		objects.circle_test.position.x = state.window_size.w * 0.5
		objects.circle_test.position.y = state.window_size.h * 0.5
		objects.circle_test.radius = state.window_size.d * 0.5
	}

	// RECENTER world
	if ( !hone_globe_tl ) {
		
		if ( spin_globe_tl ) spin_globe_tl.pause()


		let current_x = Number( String( nodes.world_wrap.style.backgroundPosition ).split( 'px' )[ 0 ] )
		let current_y = Number( String( nodes.world_wrap.style.backgroundPosition ).split( 'px' )[ 1 ] )

		current_x = ( current_x === '' || !current_x ) ? 0 : current_x
		current_y = ( current_y === '' || !current_y ) ? 0 : current_y
		let target_y = current_y

		if ( state.window_size.aspect >= ratio ) {

			target_y = spin_globe_lon * ( state.window_size.h - ( world_tex_asset.y * state.window_size.w / world_tex_asset.x ) )//( state.window_size.w / world_tex_asset.x ) * (  / world_tex_asset.y )

		}
		else {

			target_y = 0.0

		}
		
		gsap.set( 
			nodes.world_wrap,
			{ backgroundPosition: `${ current_x }px ${ target_y }px` }
		)

	}
	else {

		let o = gsap.getProperty( nodes.world_wrap, 'opacity' )

		if ( o > 0 ) {

			gsap.to(
				nodes.world_wrap,
				0.25,
				{ opacity: 0 }
			)

		}

	}

}


let debounce_timeline_reset_timer = null

let debounce_timeline_reset =() => {

	clearTimeout( debounce_timeline_reset_timer )
	debounce_timeline_reset_timer = setTimeout( timeline_reset, 500 )

}


let timeline_reset = () => {

	if ( spin_globe_tl ) {
		
		spin_globe()

	}
	else if ( poll.status.init ) {

		hone_globe( controller.SESSION_LOCATION, true )

	}

}


let loop_bg_helper = ( val ) => {
	let x = Number( val.split( 'px' )[ 0 ] )
	let y = Number( val.split( 'px' )[ 1 ] )
	return `${ x }px ${ y }px`
}



let spin_globe_lon = 0.45

let spin_globe_tl = null

export let spin_globe = () => {

	if ( hone_globe_tl ) {
		hone_globe_tl.pause()
		hone_globe_tl.kill()
		hone_globe_tl = null
	}

	if ( spin_globe_tl ) {
		spin_globe_tl.pause()
		spin_globe_tl.kill()
		spin_globe_tl = null
	}

	spin_globe_tl = gsap.timeline( {
		paused: true,
		repeat: -1,
		onComplete: () => {

		}
	} )



	let current_x = Number( String( nodes.world_wrap.style.backgroundPosition ).split( 'px' )[ 0 ] )
	let current_y = Number( String( nodes.world_wrap.style.backgroundPosition ).split( 'px' )[ 1 ] )

	current_x = ( current_x === '' || !current_x ) ? 0 : current_x
	current_y = ( current_y === '' || !current_y ) ? 0 : current_y


	let target_x = current_x + state.window_size.w * world_tex_scale

	let target_y = current_y

	let ratio = ( world_tex_asset.x / world_tex_asset.y )
	if ( state.window_size.aspect >= ratio ) {

		// FILL WIDTH, TRIM HEIGHT
		// need to shift up to center
		target_y = 
			spin_globe_lon * ( state.window_size.h - 
				( world_tex_asset.y * state.window_size.w / world_tex_asset.x ) 
			)

	}
	else {

		target_y = 0.0

	}

	if ( Math.round( target_y ) === Math.round( current_y ) ) {
		
		spin_globe_tl.add( gsap.to( 
			nodes.world_wrap,
			60,
			{
				backgroundPosition: `${ target_x }px ${ current_y }px`,
				ease: Power0.easeNone,
				modifiers: {
					backgroundPosition: loop_bg_helper
				}
			}
		), 0 )

		spin_globe_tl.play()

	}
	else {

		gsap.to(
			nodes.world_wrap,
			4.0,
			{
				backgroundPosition: `${ current_x }px ${ target_y }px`,
				backgroundSize: String( 100 * world_tex_scale ) + "%",
				ease: Power2.easeInOut,

				onComplete: () => {

					if ( spin_globe_tl ) {

						spin_globe_tl.add( gsap.to( 
							nodes.world_wrap,
							60,
							{
								backgroundPosition: `${ target_x }px ${ target_y }px`,
								ease: Power0.easeNone,
								modifiers: {
									backgroundPosition: loop_bg_helper
								}
							}
						), 0 )

						spin_globe_tl.play()

					}

				}

			}

		)

	}



}




let hone_ease = CustomEase.create("custom", "M0,0,C0.51,0,0.298,1,1,1")

let hone_zoom = 4.0

let city_locations = {
	'SIN': { x: 3229, y: 1007 },
	'LON': { x: 2052, y: 438 },
	'DUB': { x: 1976, y: 417 },
	'NYC': { x: 1206, y: 560 },
	'MPK': { x: 657, y: 598 }
}

let hone_globe_tl

let master_resolve = null

export let hone_globe = async ( city, fade ) => {

	await new Promise( resolve => {

		if ( !master_resolve ) {

			master_resolve = resolve

		}

		let was_spinning = ( spin_globe_tl !== null )

		if ( spin_globe_tl ) {
			spin_globe_tl.pause()
			spin_globe_tl.kill()
			spin_globe_tl = null
		}

		if ( hone_globe_tl ) {
			hone_globe_tl.pause()
			hone_globe_tl.kill()
			hone_globe_tl = null
		}

		hone_globe_tl = gsap.timeline( {
			paused: true,
			onComplete: () => {

				// stash this in case resize event happens during tween to beacon view
				if ( master_resolve ) {
					master_resolve()
					master_resolve = null
				}

				resolve()

			}
		} )



		let current_x = Number( String( nodes.world_wrap.style.backgroundPosition ).split( 'px' )[ 0 ] )
		let current_y = Number( String( nodes.world_wrap.style.backgroundPosition ).split( 'px' )[ 1 ] )

		current_x = ( current_x === '' || !current_x ) ? 0 : current_x
		current_y = ( current_y === '' || !current_y ) ? 0 : current_y



		let slow_down_time = 0.25
		let slow_down = state.window_size.w * world_tex_scale / 60 * slow_down_time

		let target = {
			x: -( city_locations[ city ].x / world_tex_asset.x ) * state.window_size.w * world_tex_scale * hone_zoom + state.window_size.w * 0.5,
			y: -( city_locations[ city ].y / world_tex_asset.y ) * state.window_size.h * hone_zoom + state.window_size.h * 0.5
		}


		if ( was_spinning ) {

			hone_globe_tl.add( gsap.to(
				nodes.world_wrap,
				slow_down_time,
				{
					backgroundPosition: `${ current_x + slow_down }px ${ current_y }px`,
					ease: Power1.easeOut,
					modifiers: {
						backgroundPosition: loop_bg_helper
					}
				}
			), 0 )

		}


		hone_globe_tl.add( gsap.to(
			nodes.world_wrap,
			3,
			{
				backgroundPosition: `${ Math.round( target.x ) }px ${ Math.round( target.y ) }px`,
				ease: hone_ease
			}
		), was_spinning ? slow_down_time : 0 )

		hone_globe_tl.add( gsap.to(
			nodes.world_wrap,
			3,
			{
				backgroundSize: String( 100 * world_tex_scale * hone_zoom ) + "%",
				ease: hone_ease
			}
		), was_spinning ? slow_down_time : 0 )



		let o = gsap.getProperty( nodes.world_wrap, 'opacity' )

		if ( o < 1 ) {

			hone_globe_tl.add( gsap.to(
				nodes.world_wrap,
				0.25,
				{ opacity: 1 }
			), was_spinning ? slow_down_time + 2 : 2 )

		}


		hone_globe_tl.play()


	} )

}