import { gsap, CustomEase, SteppedEase, Draggable, DrawSVGPlugin, Power2, Power1, Power0, Circ, SplitText, InertiaPlugin } from 'gsap'
gsap.registerPlugin( CustomEase, SteppedEase, DrawSVGPlugin, Draggable, InertiaPlugin, SplitText )

import * as utils from 'utils'
import * as poll from './../sections/poll'
import * as content from './../components/content'
import Question from './Question'
import { nodes } from 'nodes'
import * as palette from './../components/palette'


export default class Multiplechoice extends Question {

	constructor( root_node, data ) {

		super( root_node, data )

		this.responses = data.responses


		// - - - NODES

		this.nodes.response_list = utils.qs( '.response-list', this.nodes.view )
		this.nodes.response_options = utils.qsa( '.response-option', this.nodes.view )
		this.nodes.keys = utils.qsa( '.key-color', this.nodes.view )
		this.nodes.bg_ring = utils.qs( '.ring-wrap svg .bg-ring', this.nodes.view )
		this.nodes.rings = utils.qsa( '.ring-wrap svg .ring', this.nodes.view ).reverse()
		// this.nodes.total_counts = utils.qsa( '.total-count', this.nodes.view )

		this.nodes.total_labels = {}
		this.nodes.labels = {}
		if ( this.location === 'GLOBAL' ) {
			[].concat( content.locs, [ 'SUMMARY' ] ).forEach( l => {
				this.nodes.labels[ l ] = utils.qsa( `.labels-wrap .${ l } .label`, this.nodes.view )
				this.nodes.total_labels[ l ] = utils.qs( `.${ l } .total-count`, this.nodes.view )
			} )
		}
		else {
			this.nodes.labels[ 'SUMMARY' ] = utils.qsa( `.labels-wrap .SUMMARY .label`, this.nodes.view )
			this.nodes.total_labels[ 'SUMMARY' ] = utils.qs( `.labels-group.SUMMARY .total-count`, this.nodes.view )
		}

		this.nodes.labels_inner = {}
		for ( let loc in this.nodes.labels ) {
			this.nodes.labels_inner[ loc ] = utils.qsa( '.label-inner', utils.qs( `.labels-wrap .${ loc }`, this.nodes.view ) )
		}

		this.nodes.all_labels = []
		for ( let loc in this.nodes.labels ) {
			this.nodes.all_labels = this.nodes.all_labels.concat( this.nodes.labels[ loc ] )
			this.nodes.all_labels.push( this.nodes.total_labels[ loc ] )
		}

		this.test_cache()


		this._show_next_button_timer = null



		// - - - LISTENERS

		// this.nodes.response_list.addEventListener( 'click', ( e ) => { this.response_handler( e ) } )


	}

	/*
	response_handler( event ) {

		let e = utils.norm_click( event )
		let el = utils.walking_class_test( e.path[ 0 ], 'response-option' )
		
		if ( el ) {
			let i = Number( el.dataset['index'] )
			this.select_response( i )
		}

	}
	*/



	build_draggable() {


		if ( this._scroll_tls ) {
			this._scroll_tls.forEach( ( tl, i ) => {
				this._scroll_tls[ i ].kill()
				this._scroll_tls[ i ] = null
			} )
			this._scroll_tls = []
		}
		else {
			this._scroll_tls = []
		}

		this.module_h = this.nodes.response_options[ 0 ].clientHeight
		this.total_h = ( this.responses.length ) * this.module_h

		this.start_y = -this.module_h * ( this.responses.length * 0.5 - 0.5 )
		this.end_y = this.start_y + ( this.responses.length - 1 ) * this.module_h


		this.response_wrap_height = this.nodes.response_list.clientHeight

		let spread_y = Math.max( Math.min( 120, this.response_wrap_height * 0.5 ), 50 )
		let twist = 80
		let scale = 1.15

		// - - - TWEENS per row

		this.nodes.response_options.forEach( ( row, i ) => {

			let tl = gsap.timeline( {
				paused: true
			} )

			// slide
			tl.add( gsap.fromTo(
				row, 0.5,
				{
					y: spread_y,
				},
				{
					y: 0,
					ease: Power2.easeIn
				}
			), 0 )

			tl.add( gsap.to(
				row, 0.5,
				{
					y: -spread_y,
					ease: Power2.easeOut
				}
			), 0.5 )


			
			// scale
			tl.add( gsap.fromTo(
				row, 0.15,
				{
					scale: 1,
				},
				{
					scale: scale,
					transformOrigin: "50% 50%",
					ease: Power2.easeInOut
				}
			), 0.35 )

			tl.add( gsap.to(
				row, 0.15,
				{
					scale: 1,
					ease: Power2.easeInOut
				}
			), 0.5 )


			// opacity
			tl.add( gsap.fromTo(
				row, 0.5,
				{
					opacity: 0,
				},
				{
					opacity: 1,
					ease: Power2.easeIn
				}
			), 0.0 )

			tl.add( gsap.to(
				row, 0.5,
				{
					opacity: 0,
					ease: Power2.easeOut
				}
			), 0.5 )


			// twist
			tl.add( gsap.fromTo(
				row, 0.5,
				{
					rotateX: -twist,
				},
				{
					rotateX: 0,
					ease: Power2.easeIn
				}
			), 0 )

			tl.add( gsap.to(
				row, 0.5,
				{
					rotateX: twist,
					ease: Power2.easeOut
				}
			), 0.5 )


			this._scroll_tls.push( tl )

		} )


		// - - - PROXIED DRAGGABLE

		if ( this.nodes.scroll_proxy ) {
			this.nodes.scroll_proxy.remove()
			this.nodes.scroll_proxy = null
		}

		if ( this._draggable && this._draggable[ 0 ] ) {
			this._draggable[ 0 ].kill()
			this._draggable[ 0 ] = null
			this._draggable = null
		}

		this.nodes.scroll_proxy = document.createElement( 'div' )
		this._draggable = Draggable.create( 
			this.nodes.scroll_proxy, 
			{
				type: 'y',
				lockAxis: true,
				trigger: this.nodes.response_list,
				inertia: true,
				edgeResistance: 1,
				throwResistance: 3000,
				minDuration: 0.5,
				maxDuration: 0.5,
				bounds: {
					minY: 0,
					maxY: this.total_h 
				},
				snap: ( y ) => { return this.snap_helper( y ) },
				onPress: () => {
					if ( this._scroll_to_tl ) {
						this._scroll_to_tl.pause()
						this._scroll_to_tl.kill()
						this._scroll_to_tl = null
					}
				},
				onDragStart: ( e ) => {
					this.drag_start_pos = this._draggable[ 0 ].y
				},
				onDrag: () => { 
					this.update_scroll()
				},
				onThrowUpdate: () => { 
					this.update_scroll()
				},
				onClick: ( e ) => {
					
					let dir = 0
					if ( e.layerY / this.response_wrap_height > 0.6 ) dir = 1
					if ( e.layerY / this.response_wrap_height < 0.4 ) dir = -1

					let i = utils.clamp( this.response_index + dir, 0, this.responses.length - 1 )

					if ( this.response_index === null && dir === 0 ) {
						this.update_scroll()
					}
					else if ( i !== this.response_index ) {

						let y = (
							( ( this.responses.length - 1 ) - i ) * 
							( this._draggable[ 0 ].maxY - this._draggable[ 0 ].minY )  /
							( this.responses.length - 1 )
						) + this._draggable[ 0 ].minY

						this._scroll_to_tl = gsap.to(
							this._draggable[ 0 ].target,
							0.25,
							{
								y: y,
								onUpdate: () => {
									this._draggable[ 0 ].update()
									this.update_scroll()
								}
							}
						)

					}

				},
				onDragEnd: () => {

				},
				onRelease: () => {

				},
				onThrowComplete: () => {
					// this.finish_range()
				}
			}
		)

		gsap.set( 
			this._draggable[ 0 ].target, 
			{ y: this.total_h }
		)

		this._draggable[ 0 ].applyBounds()
		this._draggable[ 0 ].update()

		this.update_scroll( true )


	}

	update_scroll( block_paint ) {

		let n = 
			( this._draggable[ 0 ].y - this._draggable[ 0 ].minY ) /
			( this._draggable[ 0 ].maxY - this._draggable[ 0 ].minY )

		this.scroll_norm = 1.0 - utils.clamp( n, 0, 1 )

		let index_tween = this.scroll_norm * ( this.responses.length - 1 )
		
		let index = Math.round( index_tween )

		if ( !block_paint && index !== this.response_index ) {
			this.select_response( index )
		}

		this._scroll_tls.forEach( ( tl, i ) => {

			let rel_index = ( index_tween - i )

			let rel_norm = utils.normalize( rel_index, -4, 4, true )

			tl.progress( rel_norm )

		} )

	}

	snap_helper( end_value ) {

		let norm =
			( end_value - this._draggable[ 0 ].minY ) /
			( this._draggable[ 0 ].maxY - this._draggable[ 0 ].minY )

		norm = utils.clamp( norm, 0, 1 )

		let index = Math.round( norm * ( this.responses.length - 1 ) )


		// - - - BUMP, give some extra oomph to a small but intentional swipe

		let bump_threshold = 6
		let vel_threshold = 100

		let vel = Math.abs( InertiaPlugin.getVelocity( this._draggable[ 0 ].target, "y" ) )

		if ( 
			Math.abs( this.drag_start_pos - end_value ) > bump_threshold  &&
			vel > vel_threshold &&
			index === this.response_index
		) {
			
			let dir = ( end_value > this.drag_start_pos )
				? 1
				: -1

			index += dir

			index = utils.clamp( index, 0, this.responses.length - 1 )

		}

		let y = this.total_h / ( this.responses.length - 1 )
		
		y *= index

		return y
		
	}


	select_response( index ) {

		if ( !( index >= 0 && index < this.responses.length ) ) {
			console.log( 'Error: cannot select, index out of range', index )
			return
		}

		this.response_index = index

		poll.sync_submit_button()

	}


	reset_response( force ) {

		if ( force || ( this.response_index !== null && !this.submitted ) ) {
			this.response_index = null

			this.nodes.response_options.forEach( el => {
				el.classList.remove( 'selected' )
			} )

			poll.sync_submit_button()

		}

	}


	reset_dataviz() {

		this.loc_in_view = 'SUMMARY'


	}



	update_dataviz() {

		if ( this.location === 'GLOBAL' ) {

			for ( let loc in this.data ) {

				let s = 0; 							// sum
				let tt = this.data[ loc ].total 	// total

				let d = this.data[ loc ]
				d.totals.forEach( ( t, i ) => {
				
					this.nodes.labels_inner[ loc ][ i ].innerHTML = 
							`${ Math.round( 100 * ( t / d.total ) ) }%`
				
					let ss = s + 0
					s += ( t / tt )

					if ( t === 0 ) {

						gsap.set(
							this.nodes.labels[ loc ][ i ],
							{ scale: 0 }
						)

					}
					else {

						gsap.set(
							this.nodes.labels[ loc ][ i ],
							{ scale: 1 }
						)

					}

					gsap.to(
						this.nodes.labels[ loc ][ i ],
						0.25,
						{
							rotate: 360 * utils.mix( 0.5, s, ss ),
							transformOrigin: "50% 100%"
						}
					)

					gsap.to(
						this.nodes.labels_inner[ loc ][ i ],
						0.25,
						{
							rotate: -360 * utils.mix( 0.5, s, ss ),
							transformOrigin: "50% 50%"
						}
					)

				} )

				this.nodes.total_labels[ loc ].innerHTML = `<div> ${ tt } </div><div>Votes</div>`

			}

		}
		else {

			let loc = 'SUMMARY'
			let d = this.data[ loc ]
			let s = 0; 							// sum
			let tt = this.data[ loc ].total 	// total

			d.totals.forEach( ( t, i ) => {
			
				this.nodes.labels_inner[ loc ][ i ].innerHTML = 
						`${ Math.round( 100 * ( t / d.total ) ) }%`
			
				let ss = s + 0
				s += ( t / tt )

				if ( t === 0 ) {

					gsap.set(
						this.nodes.labels[ loc ][ i ],
						{ scale: 0 }
					)

				}
				else {

					gsap.set(
						this.nodes.labels[ loc ][ i ],
						{ scale: 1 }
					)

				}

				gsap.to(
					this.nodes.labels[ loc ][ i ],
					0.25,
					{
						rotate: 360 * utils.mix( 0.5, s, ss ),
						transformOrigin: "50% 100%"
					}
				)

				gsap.to(
					this.nodes.labels_inner[ loc ][ i ],
					0.25,
					{
						rotate: -360 * utils.mix( 0.5, s, ss ),
						transformOrigin: "50% 50%"
					}
				)

			} )

			this.nodes.total_labels[ loc ].innerHTML = `<div> ${ tt } </div><div>Votes</div>`

		}

		this.tween_updated_data()

	}


	resize() {
		
	}



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - UPDATE LOOP

	update() {

		gsap.set( 
			[ this.bg_rings.dark.node, this.bg_rings.light.node, this.fg_rings.main.node ], 
			{ x: poll.submit_position_cache.x, y: poll.submit_position_cache.y }
		)

		this.bg_rings.dark.update()
		this.bg_rings.light.update()
		this.fg_rings.main.update()

	}



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - HELPERS

	split_question() {

		if ( this.question_split ) this.question_split.revert()

		this.question_split = new SplitText( 
			this.nodes.question, 
			{ type: 'lines', linesClass: 'line' } 
		)

		this.question_split.lines.forEach( line => {
			// console.log( line )
			line.innerHTML = `<div class="line-inner">${ line.innerHTML }</div>`
			line.style.overflow = 'hidden'
		} )

		this.question_split_inners = utils.qsa( '.question .line-inner', this.nodes.view )

	}

	split_response() {

		if ( this.response_split ) this.response_split.revert()

		this.response_split = new SplitText( 
			this.nodes.submit_value, 
			{ type: 'lines', linesClass: 'line' } 
		)

		this.response_split.lines.forEach( line => {
			line.innerHTML = `<div class="line-inner">${ line.innerHTML }</div>`
			line.style.overflow = 'hidden'
		} )

		this.response_split_inners = utils.qsa( '.submit-value .line-inner', this.nodes.view )

	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  TWEENS


	async tween_in() {

		await new Promise( resolve => {

			this.tween_in_start()

			gsap.set( this.nodes.view, { opacity: 0 } )


			gsap.set(
				this.nodes.all_labels,
				{ opacity: 0 }
			)

			gsap.set(
				this.nodes.all_labels,
				{ opacity: 0 }
			)

			this.build_draggable()

			this._tween_in_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					resolve()
					if ( this.question_split ) this.question_split.revert()
					this.tween_in_complete()
					if ( this.submitted ) {
						this._show_next_button_timer = setTimeout( poll.show_next_button, 5 * 1000 )
					}
				},
				onStart: () => {

				}
			} )

				
			// Split text for question
			this.split_question()
			

			// Dataviz key color coded

			this.nodes.keys.forEach( ( k, i ) => {
				let c = palette.get( i, 'light', this.type )
				gsap.set( k, { backgroundColor: c } )
			} )

			this.nodes.rings.forEach( ( k, i ) => {
				let c = palette.get( i, 'light', this.type )
				gsap.set( k, { stroke: c, drawSVG: "0% 0%", rotate: -90, transformOrigin: "50% 50%" } )
			} )

			// TO DATA view
			if ( this.submitted ) {

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.view,
					0.25,
					{
						opacity: 0,
					},
					{
						opacity: 1
					}
				), 0 )

				this._tween_in_tl.add( gsap.to(
					this.nodes.labels[ this.loc_in_view ],
					0.25,
					{
						opacity: 1
					}
				), 0 )

				this._tween_in_tl.add( gsap.to(
					this.nodes.total_labels[ this.loc_in_view ],
					0.25,
					{
						opacity: 1
					}
				), 0 )

				gsap.set( 
					this.nodes.question_small_a_wrap,
					{ opacity: 1 }
				)

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.question_small,
					0.25,
					{
						opacity: 0,
					},
					{
						opacity: 1
					}
				), 0.0 )

				let s = 0; 										// sum
				let tt = this.data[ this.loc_in_view ].total 	// total

				this.data[ this.loc_in_view ].totals.forEach( ( t, i ) => {
					
					let ss = s + 0
					s += ( t / tt )

					this._tween_in_tl.add( gsap.to(
						this.nodes.rings[ i ], 0.5, { drawSVG: `0% ${ 100 * s }%` }
					), 0.25 )

					this._tween_in_tl.add( gsap.to(
						this.nodes.rings[ i ], 0.1, { drawSVG: `${ 100 * ss }% ${ 100 * s }%` }
					), 0.75 )

				} )

				gsap.set( this.bg_rings.dark.node, { scale: 1, opacity: 0, transformOrigin: "50% 50%" } )

				this._tween_in_tl.add( gsap.to(
					this.bg_rings.dark.node,
					0.5,
					{
						opacity: 1
					}
				), 0 )
				
			}

			// TO RESPONSE view
			else {

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.view,
					0.1,
					{
						opacity: 0,
					},
					{
						opacity: 1
					}
				), 0 )

				this._tween_in_tl.add( gsap.fromTo(
					this.question_split_inners,
					0.4,
					{
						y: "+=100%"
					},
					{
						y: "0%",
						ease: Power2.easeOut,
						stagger: 0.15
					}

				), 0.4 )

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.loc_eyebrow,
					0.2,
					{
						opacity: 0,
						y: 10
					},
					{
						opacity: 0.5,
						y: 0,
						ease: Power2.easeOut
					}
				), 0.7 )

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.divider,
					0.3,
					{
						scaleX: 0
					},
					{
						scaleX: 1,
						transformOrigin: "0% 50%",
						ease: this.wipe_ease
					}

				), 0.2 )

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.response_list,
					0.2,
					{
						opacity: 0,
					},
					{
						opacity: 1,
						stagger: 0.05
					}
				), 0.8 )

			}

			this._tween_in_tl.play()

		} )

	}


	async tween_out() {
		
		await new Promise( resolve => {

			this._tween_out_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					resolve()
					this.tween_out_complete()
				},
				onStart: () => {
					this.tween_out_start()
				}
			} )

			// FROM DATA view
			if ( this.submitted ) {

				this._tween_out_tl.add( gsap.fromTo(
					this.nodes.view,
					0.25,
					{
						opacity: 1,
					},
					{
						opacity: 0
					}
				), 0 )

				this._tween_out_tl.add( gsap.to(
					this.bg_rings.dark.node,
					0.5,
					{
						opacity: 0
					}
				), 0 )

			}

			// FROM RESPONSE view
			else {

				this._tween_out_tl.add( gsap.fromTo(
					this.nodes.view,
					0.25,
					{
						opacity: 1,
					},
					{
						opacity: 0
					}
				), 0 )	

			}

			this._tween_out_tl.play()

		} )

	}




	async tween_submit() {

		await new Promise( resolve => {

			gsap.set( this.nodes.submit_wrap, { opacity: 0 } )
		
			this.nodes.submit_wrap.classList.add( 'visible' )

			this.split_response()

			this._submit_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					if ( this.response_split ) this.response_split.revert()
					this.nodes.response_wrap.classList.remove( 'visible' )
					this._submit_tl.kill()
					this._submit_tl = null
					resolve()
				},
				onStart: () => {
					this.set_interactive( false )
				}
			} )

			this._submit_tl.add( gsap.fromTo(
				this.nodes.response_wrap,
				0.25,
				{
					opacity: 1,
				},
				{
					opacity: 0
				}
			), 0 )

			this._submit_tl.add( gsap.fromTo(
				this.bg_rings.dark.node,
				2.0,
				{
					scale: 0,
				},
				{
					scale: 1,
					transformOrigin: "50% 50%",
					ease: this.wipe_ease
				}
			), 0 )

			this._submit_tl.add( gsap.fromTo(
				this.bg_rings.light.node,
				1.75,
				{
					scale: 0,
					opacity: 1,
				},
				{
					opacity: 0.0,
					scale: 0.75,
					transformOrigin: "50% 50%",
					ease: this.wipe_ease
				}
			), 0.2 )

			this._submit_tl.add( gsap.fromTo(
				this.fg_rings.main.node,
				1.75,
				{
					scale: 0,
					opacity: 1,
				},
				{
					opacity: 0.0,
					scale: 1.0,
					transformOrigin: "50% 50%",
					ease: this.wipe_ease
				}
			), 0.4 )

			this._submit_tl.add( gsap.fromTo(
				this.nodes.submit_wrap,
				0.15,
				{
					opacity: 0,
				},
				{
					opacity: 1
				}
			), 0.25 )


			this._submit_tl.add( gsap.fromTo(
				[ this.nodes.submit_label_inner ].concat( this.response_split_inners ),
				0.4,
				{
					y: "+=100%"
				},
				{
					y: "0%",
					ease: Power2.easeOut,
					stagger: 0.15
				}
			), 0.25 )
			

			this._submit_tl.add( gsap.fromTo(
				this.nodes.question_small,
				0.25,
				{
					opacity: 0,
				},
				{
					opacity: 1
				}
			), 0.25 )

			gsap.set( 
				this.nodes.question_small_a_wrap,
				{ opacity: 0 }
			)

			this._submit_tl.play()

		} )

	}


	async tween_data() {

		await new Promise( resolve => {

			this._data_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					this.nodes.submit_wrap.classList.remove( 'visible' )
					this._data_tl.kill()
					this._data_tl = null
					this.set_interactive( true )
					this._show_next_button_timer = setTimeout( poll.show_next_button, 5 * 1000 )
					resolve()
				},
				onStart: () => {
					this.nodes.data_wrap.classList.add( 'visible' )
				}
			} )

			this.nodes.keys.forEach( ( k, i ) => {
				let c = palette.get( i, 'light', this.type )
				gsap.set( k, { backgroundColor: c } )
			} )

			this.nodes.rings.forEach( ( k, i ) => {
				let c = palette.get( i, 'light', this.type )
				gsap.set( k, { stroke: c, drawSVG: "0% 0%", rotate: -90, transformOrigin: "50% 50%" } )
			} )

			this._data_tl.add( gsap.fromTo(
				this.nodes.submit_wrap,
				0.25,
				{
					opacity: 1,
				},
				{
					opacity: 0
				}
			), 0.0 )

			this._data_tl.add( gsap.fromTo(
				this.nodes.data_wrap,
				0.25,
				{
					opacity: 0,
				},
				{
					opacity: 1
				}
			), 0.25 )


			let s = 0; 										// sum
			let tt = this.data[ this.loc_in_view ].total 	// total

			this.data[ this.loc_in_view ].totals.forEach( ( t, i ) => {
				
				let ss = s + 0
				s += ( t / tt )

				this._data_tl.add( gsap.to(
					this.nodes.rings[ i ], 0.5, { drawSVG: `0% ${ 100 * s }%` }
				), 0.25 )

				this._data_tl.add( gsap.to(
					this.nodes.rings[ i ], 0.1, { drawSVG: `${ 100 * ss }% ${ 100 * s }%` }
				), 0.75 )


			} )


			this._data_tl.add( gsap.to(
				this.nodes.labels[ this.loc_in_view ],
				0.25,
				{
					opacity: 1
				}
			), 0.25 )

			this._data_tl.add( gsap.to(
				this.nodes.total_labels[ this.loc_in_view ],
				0.25,
				{
					opacity: 1
				}
			), 0.25 )


			this._data_tl.add( gsap.fromTo(
				this.nodes.question_small_a_wrap,
				0.25,
				{
					opacity: 0,
				},
				{
					opacity: 1
				}
			), 0.25 )


			this._data_tl.play()

		} )

	}


	to_loc_chain() {

		if ( this.to_loc_chain_dirty ) {

			this._loc_tl.pause()
			this._loc_tl.kill()
			this._loc_tl = null

			this.tween_loc( this.to_loc_chain_dirty )

			this.to_loc_chain_dirty = false

		}

	}


	async tween_loc( loc ) {

		await new Promise( async resolve => {

			if ( this._loc_tl ) {
				this.to_loc_chain_dirty = loc
				return
			}

			if ( !(
				this.nodes.labels[ this.loc_in_view ] && 
				this.nodes.labels[ this.loc_in_view ].length > 0  && 
				this.nodes.labels[ loc ] && 
				this.nodes.labels[ loc ].length > 0
			) ) {
				console.log( 'Error: cannot tween to or from invalid location slugs', this.loc_in_view, loc, this.nodes.labels )
				return
			}

			if ( loc === this.loc_in_view ) {
				return
			}

			this._loc_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					if ( this.to_loc_chain_dirty ) {

						this.to_loc_chain()

					}
					else {
						resolve()
						this._loc_tl.pause()
						this._loc_tl.kill()
						this._loc_tl = null
					}
				},
				onStart: () => {

				}
			} )



			let s = 0; 							// sum
			let tt = this.data[ loc ].total 	// total

			if ( tt  > 0 ) {

				this.data[ loc ].totals.forEach( ( t, i ) => {
					
					let ss = s + 0
					s += ( t / tt )

					this._loc_tl.add( gsap.to(
						this.nodes.rings[ i ], 0.5, { drawSVG: `${ 100 * ss }% ${ 100 * s }%` }
					), 0.0 )

				} )

			}
			else {

				this.data[ loc ].totals.forEach( ( t, i ) => {

					this._loc_tl.add( gsap.to(
						this.nodes.rings[ i ], 0.5, { 
							drawSVG: `0% 0%`
						}
					), 0.0 )

				} )

			}

			this._loc_tl.add( gsap.to(
				this.nodes.labels[ this.loc_in_view ],
				0.25,
				{ opacity: 0 }
			), 0 )

			this._loc_tl.add( gsap.to(
				this.nodes.total_labels[ this.loc_in_view ],
				0.25,
				{ opacity: 0 }
			), 0 )

			this._loc_tl.add( gsap.to(
				this.nodes.labels[ loc ],
				0.25,
				{ opacity: 1 }
			), 0.25 )

			this._loc_tl.add( gsap.to(
				this.nodes.total_labels[ loc ],
				0.25,
				{ opacity: 1 }
			), 0.25 )


			this.loc_in_view = loc

			this._loc_tl.play()

		} )

	}


	tween_updated_data() {

		if ( !this.submitted && !this.visible ) {
			return
		}

		if ( this._data_update_tl ) {
			this._data_update_tl.pause()
			this._data_update_tl.kill()
			this._data_update_tl = null
		}

		this._data_update_tl = gsap.timeline( {
			paused: true,
			onComplete: () => {

			},
			onStart: () => {

			}
		} )


		let s = 0; 										// sum
		let tt = this.data[ this.loc_in_view ].total 	// total

		if ( tt > 0 ) {

			this.data[ this.loc_in_view ].totals.forEach( ( t, i ) => {
				
				let ss = s + 0
				s += ( t / tt )

				this._data_update_tl.add( gsap.to(
					this.nodes.rings[ i ], 0.5, { 
						drawSVG: `${ 100 * ss }% ${ 100 * s }%`
					}
				), 0.0 )

			} )

		}
		else {

			this.data[ this.loc_in_view ].totals.forEach( ( t, i ) => {

				this._data_update_tl.add( gsap.to(
					this.nodes.rings[ i ], 0.5, { 
						drawSVG: `0% 0%`
					}
				), 0.0 )

			} )

		}

		this._data_update_tl.play()

	}


}