import { gsap, CustomEase, SteppedEase, DrawSVGPlugin, Power2, Power1, Power0 } from 'gsap'
import cookies from 'js-cookie'


import * as svg from './../model/svg'

import { state } from './../app/controller'

import View from './View'
import * as utils from 'utils'
import * as poll from './../sections/poll'
import { nodes } from './../components/nodes'
import * as socket from './../components/socket'
import * as content from './../components/content'
import * as controller from './../app/controller'
import * as palette from './../components/palette'


export default class Question extends View {

	constructor( root_node, data ) {

		super( root_node, data )

		this.is_question = true

		this.id = data.id
		this.question = data.question
		this.location = data.location
		this.type = data.type
		this.data = content.all.INITIAL_DATA[ this.id ]

		this.nodes.question = utils.qs( '.question', this.nodes.view )
		this.nodes.divider = utils.qs( '.divider', this.nodes.view )
		this.nodes.data_wrap = utils.qs( '.data-wrap', this.nodes.view )
		this.nodes.submit_wrap = utils.qs( '.submit-wrap', this.nodes.view )
		this.nodes.response_wrap = utils.qs( '.response-wrap', this.nodes.view )
		this.nodes.question_small = utils.qs( '.question-small', this.nodes.view )
		this.nodes.question_small_q_label = utils.qs( '.question-small .q-label', this.nodes.view )
		this.nodes.question_small_a_wrap = utils.qs( '.question-small .a-wrap', this.nodes.view )
		this.nodes.question_small_a_label = utils.qs( '.question-small .a-label', this.nodes.view )
		this.nodes.question_small_a_value = utils.qs( '.question-small .a-value', this.nodes.view )
		this.nodes.submit_label = utils.qs( '.submit-label', this.nodes.view )
		this.nodes.submit_value = utils.qs( '.submit-value', this.nodes.view )
		this.nodes.submit_label_inner = utils.qs( '.submit-label .inner', this.nodes.view )
		this.nodes.loc_eyebrow = utils.qs( '.loc-eyebrow', this.nodes.view )
		
		this.bg_rings = {
			dark: new svg.Circle( {
				radius: poll.submit_position_cache.length,
				position: new svg.Vector2( 0, 0 ),
				fillColor: "#000"
			} ),
			light: new svg.Circle( {
				radius: poll.submit_position_cache.length,
				position: new svg.Vector2( 0, 0 ),
				fillColor: "#000"
			} )
		}

		this.fg_rings = {
			main: new svg.Circle( {
				radius: poll.submit_position_cache.length,
				position: new svg.Vector2( 0, 0 ),
				strokeColor: "#000",
				strokeWidth: 20
			} )
		}

		nodes.svg_bg_root.appendChild( this.bg_rings.dark.node )
		nodes.svg_bg_root.appendChild( this.bg_rings.light.node )
		nodes.svg_fg_root.appendChild( this.fg_rings.main.node )

		gsap.set( [
			this.bg_rings.dark.node,
			this.bg_rings.light.node,
			this.fg_rings.main.node
		], { 
			scale: 0.00, 
			x: poll.submit_position_cache.x, 
			y: poll.submit_position_cache.y,
			transformOrigin: "50% 50%" 
		} )

		this.responses = []
		this.response_index = null

		this.loc_in_view = 'SUMMARY'

		this.submitted = false

		this._data_update_tl = null
		this._submit_tl = null
		this._data_tl = null
		this._loc_tl = null

		this.use_cache = Boolean( Number( process.env.USE_COOKIES ) )
		this.cache_key = `facebook_beacons_${ controller.SESSION_LOCATION }_${ this.id }_v${ 1 }_response`

		this.wipe_ease = CustomEase.create("custom", "M0,0,C0.2,0,0.2,1,1,1")

	}


	populate_response_value() {

		if ( 
			this.response_index !== null && 
			this.response_index >= 0 && 
			this.responses.length > this.response_index
		) {
			this.nodes.submit_value.innerHTML = this.responses[ this.response_index ]
			this.nodes.question_small_a_value.innerHTML = this.responses[ this.response_index ]
		}
		else {
			console.log( 'Can not populate response', this.response_index, this.responses )
		}

	}


	sync_data( data ) {

		// cache 

		this.data = data


		// calc if populated

		let threshold = 10

		if ( this.location === 'GLOBAL' ) {

			let pop_temp = true
			for ( let loc in data ) {
				pop_temp = pop_temp && ( threshold <= data[ loc ].total )
			}

			this.is_populated = pop_temp

		}
		else {

			this.is_populated = threshold <= data.SUMMARY.total
			
		}


		if ( this.is_populated ) {

			this.nodes.view.classList.remove( 'show-early-response' )

		}
		else {

			this.nodes.view.classList.add( 'show-early-response' )	

		}


		// sync view

		this.update_dataviz()

	}


	async submit() {

		if ( 
			this.response_index !== null && 
			this.response_index >= 0 && 
			this.responses.length > this.response_index &&
			this.submitted === false
		) {

			let data = {
				qid: this.id,
				location: controller.SESSION_LOCATION,
				i: this.response_index
			}

			socket.emit( 'response_submit', data )

			if ( this.use_cache ) {

				let secure = ! String( window.location.host ).includes( 'localhost' )
				cookies.set( this.cache_key, this.response_index, { sameSite: 'strict', secure: secure } )

			}

			this.submitted = true


			let color_dark = palette.get( 
				this.response_index, 
				'dark', 
				this.type,
				this.range_max
			)

			let color_light = palette.get( 
				this.response_index, 
				'light', 
				this.type,
				this.range_max
			)

			this.bg_rings.dark.fillColor = color_dark
			this.bg_rings.light.fillColor = color_light

			this.fg_rings.main.strokeColor = color_light

			this.populate_response_value()

			await this.tween_submit()

			this.tween_data()

			poll.sync_location_bar()

		}
		else {
			console.log( 'Error: cant submit invalid response_index', this.response_index )
		}

	}


	test_cache() {

		let cached_response = cookies.get( this.cache_key )
		if ( this.use_cache && cached_response !== undefined ) {

			this.submitted = true
			this.response_index = Number( cached_response )

			let data = content.all.INITIAL_DATA[ this.id ]
			this.sync_data( data )

			let color_dark = palette.get( 
				this.response_index, 
				'dark', 
				this.type,
				this.range_max
			)

			let color_light = palette.get( 
				this.response_index, 
				'light', 
				this.type,
				this.range_max
			)

			this.bg_rings.dark.fillColor = color_dark
			this.bg_rings.light.fillColor = color_light

			this.fg_rings.main.strokeColor = color_light

			this.populate_response_value()

		}

	}


}