import * as utils from 'utils'


export let nodes = {
	home: {},
	poll: {},
	debug: {},
}


export let build = ( section ) => {


	if ( section === 'home' ) {
		
		nodes.home.section 						= qs( '.section.home' )
		nodes.home.logo 						= qs( '.section.home .logo' )
		nodes.home.welcome 						= qs( '.section.home .welcome' )
		nodes.home.location_select_label 		= qs( '.section.home .location-select-label' )
		nodes.home.location_select				= qs( '.section.home .location-select' )
		nodes.home.location_select_wrap			= qs( '.section.home .location-select-wrap' )
		nodes.home.begin_button					= qs( '.section.home .begin-button' )
		nodes.home.block_1 						= qs( '.section.home .block-1' )
		nodes.home.block_2 						= qs( '.section.home .block-2' )
		nodes.home.block_3 						= qs( '.section.home .block-3' )
		nodes.home.list_wrap 					= qs( '.section.home .block-2 .list-wrap' )
		nodes.home.back_button 					= qs( '.section.home .block-2 .back-button' )
		nodes.home.list_label 					= qs( '.section.home .block-2 .list-label' )
		nodes.home.begin_button_alt				= qs( '.section.home .block-2 .begin-button-alt' )

		nodes.home.code_back_button				= qs( '.section.home .block-3 .back-button' )
		nodes.home.code_list_label				= qs( '.section.home .block-3 .list-label' )
		nodes.home.code_input					= qs( '.section.home .block-3 .entry-wrap input' )
		nodes.home.code_begin_button_alt		= qs( '.section.home .block-3 .begin-button-alt' )
		nodes.home.code_list_cta 				= qs( '.section.home .block-3 .list-cta' )

	}
	if ( section === 'poll' ) {
		
		nodes.poll.section 						= qs( '.section.poll' ) 
		nodes.poll.section_inner				= qs( '.section.poll .inner' ) 
		nodes.poll.nav_wrap 					= qs( '.section.poll .nav-bar-wrap' ) 
		nodes.poll.nav_button_back				= qs( '.section.poll .button-back' ) 
		nodes.poll.nav_button_next				= qs( '.section.poll .button-next' ) 
		nodes.poll.nav_progress_wrap 			= qs( '.section.poll .progress-wrap' )
		nodes.poll.nav_progress_modules 		= qsa( '.section.poll .progress-module' )

		nodes.poll.modules_wrap 				= qs( '.section.poll .modules-wrap' )
		
		nodes.poll.submit_button				= qs( '.section.poll .submit-button' )
		nodes.poll.submit_check					= qs( '.section.poll .submit-button .check' )
		nodes.poll.submit_svg					= qs( '.section.poll .submit-button svg' )
		nodes.poll.submit_svg_line				= qs( '.section.poll .submit-button svg .check-line' )

		nodes.poll.location_bar 				= qs( '.section.poll .locations-wrap' )
		nodes.poll.location_bar_global			= qs( '.section.poll .locations-wrap .inner .global' )
		nodes.poll.location_bar_local			= qs( '.section.poll .locations-wrap .inner .local' )
		nodes.poll.locations 	 				= qsa( '.section.poll .locations-wrap .global .location' )

	}
	else if ( section === 'debug' ) {
		
		nodes.debug.wrap = qs('.section.debug')
		nodes.debug.questions = {}

		nodes.debug.backdrop = {
			toggle: qs('.section.debug .backdrop-wrapper .backdrop-option')
		}

		// QUESTION selectors
		nodes.debug.question_select = {
			wrap: qs('.section.debug'),
			questions: qsa('.section.debug .question-wrapper .question-selector')
		}

		// SCENE selectors
		nodes.debug.scenes = {
			'hero': {
				wrap: qs('.section.debug .scene-wrapper .hero-scenes'),
				scenes: qsa('.section.debug .scene-wrapper .hero-scenes .scene-option')
			},
			'side_l': {
				wrap: qs('.section.debug .scene-wrapper .side-l-scenes'),
				scenes: qsa('.section.debug .scene-wrapper .side-l-scenes .scene-option')
			},
			'side_r': {
				wrap: qs('.section.debug .scene-wrapper .side-r-scenes'),
				scenes: qsa('.section.debug .scene-wrapper .side-r-scenes .scene-option')
			},
			'conf': {
				wrap: qs('.section.debug .scene-wrapper .conf-scenes'),
				scenes: qsa('.section.debug .scene-wrapper .conf-scenes .scene-option')
			}
		}

		nodes.debug.locations = qsa('.section.debug .location-option')

		// QUESTION response submit forms
		let temp = qsa( '.section.debug .question-module' )
		temp.forEach( q => {
			let qid = q.dataset.qid
			nodes.debug.questions[  qid ] = { module: q }
			if ( q.dataset.questiontype === 'MULTIPLECHOICE' || q.dataset.questiontype === 'BINARY' ) {
				nodes.debug.questions[ qid ].responses = qsa( '.response', q )
				nodes.debug.questions[ qid ].submit = qs( '.submit', q )
			}
			else if ( q.dataset.questiontype === 'RANGE' ) {
				nodes.debug.questions[ qid ].range = qs( '.range-slider', q )
				nodes.debug.questions[ qid ].label = qs( '.range-label', q )
				nodes.debug.questions[ qid ].submit = qs( '.submit', q )
			}
			nodes.debug.questions[ qid ].datacolumns = {}
			let cols = qsa( '.location-wrap', q )
			cols.forEach( x => {
				let slug = x.dataset.location
				nodes.debug.questions[ qid ].datacolumns[ slug ] = qsa( '.location-value', x )
			} )
		} )

		nodes.debug.location_select=  qs( '.section.debug .location-select' )
	}
	else {

		nodes.body = qs('body')


		nodes.world_wrap = qs('.world-wrap')
		nodes.svg_bg_wrap = qs('.svg-wrap.bg')
		nodes.svg_bg = qs('.svg-wrap.bg svg')
		nodes.svg_bg_root = qs('.svg-wrap.bg svg .root')
		nodes.svg_bg_beacon = qs('.svg-wrap.bg svg .beacon')

		nodes.svg_fg_wrap = qs('.svg-wrap.main')
		nodes.svg_fg = qs('.svg-wrap.main svg')
		nodes.svg_fg_root = qs('.svg-wrap.main svg .root')
		nodes.container_outer = qs('.container')
		nodes.container = qs('.container-inner')

		// Disable Context Menu (appears on long press sometimes)
		/*nodes.container.oncontextmenu = (event) => {
			event.preventDefault()
			event.stopPropagation()
			return false
		}*/

		// console.log( '%c nodes ', 'background: #009999; color: #fff', nodes )

	}

}



// Some syntactic sugar, my neighbor
let qs = utils.qs
let qsa = utils.qsa