import * as nodes from './components/nodes'
import * as navigation from './components/navigation'
import * as lazyload from './components/lazyload'
import * as ajax from './components/ajax'
import * as content from './components/content'
import * as controller from './app/controller'
import * as socket from './components/socket'
import * as pages from './sections'

const main = () => {

	if ( !content.all.loaded ) {

		// Build nav
		nodes.build()
		controller.build()
		navigation.build()
		lazyload.build()

		let url = process.env.CMS_HOST + process.env.CMS_CONTENT_API

		// Content has not been loaded yet. Go get it!
		ajax.get( url, ( body ) => {
			content.store_general( body, async () => {
				await socket.build()
				navigation.locate( true )
				navigation.locate()
			} )
		} )

	}


}

document.addEventListener('DOMContentLoaded', main)