import * as ajax from './../components/ajax'
import * as whatwg_fetch from 'whatwg-fetch'
import short_id from 'short-unique-id'

import Page from './../model/Page'
import template from './../../templates/debug.dot'

import * as controller from './../app/controller'
import { build as nodes_build } from './../components/nodes'
import { nodes as nodes } from './../components/nodes'
import * as socket from './../components/socket'
import * as utils from './../components/utils'
import * as world from './world'
import * as content from './../components/content'




// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - SETUP


let status = {

	qid: null,
	i: null,
	location: null

}

let scenes = {
	hash: null,
	scenes: {}
}

let active_question = null

let my_location = null



export let build = async () => {

	// COLLECT nodes within template
	nodes_build( 'debug' )

	nodes.debug.wrap.addEventListener( 'click', normalize_click )

	nodes.debug.wrap.addEventListener( 'mousemove', normalize_touchmouse_move )

	nodes.debug.wrap.addEventListener( 'touchmove', normalize_touchmouse_move )

	clear_options()

	my_location = content.all.GENERAL.LOCATION_SLUG
	nodes.debug.location_select.value = my_location

	sync_location()

	await fetch_data()

	request_active_question()

	request_scenes()

}


export let reconnect = async () => {

	await fetch_data()

	request_active_question()

	request_scenes()

}




// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Register Page

new Page( {

	path: 'debug',

	instance: false,

	template: template,

	get_data: ( query ) => {
		return content.all
	},

	parent: false,

	on_load: build

} )





let fetch_data = async () => {

	let url = process.env.CMS_HOST + process.env.CMS_DATA_API

	let fetch_list = content.all.QUESTIONS.map( x => { return x.id } )

	let body = JSON.stringify( { question_ids: fetch_list } )
	let data = await fetch( 
		url, 
		{ 
			method: 'POST', 
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: body
		}
	)

	let json = await data.json()

	for ( let qid in json.data ) {
		let d = json.data[ qid ]
		sync_data( qid, d )
	}


}


export let sync_data = async ( id, _data ) => {

	let qid = id
	let data = _data

	for ( let LOC in data ) {
		if ( nodes.debug.questions[ qid ].datacolumns[ LOC ] ) {
			data[ LOC ].totals.forEach( ( t, i ) => {
				nodes.debug.questions[ qid ].datacolumns[ LOC ][ i ].innerHTML = t
			} )
		}
	}

	console.log( '✨ Updated data for', qid )

}



let request_active_question = () => {
	socket.emit( 'sync_active_question' )
}

let request_scenes = () => {
	socket.emit( 'sync_scenes' )
}

export let  sync_active_question = ( data ) => {

	active_question = data

	nodes.debug.question_select.questions.forEach( q => {
		let action = ( data === q.dataset.qid ) ? 'add' : 'remove'
		q.classList[ action ]( 'selected' )
	})

	console.log( '✨ Updated active question', data )

}

export let sync_scenes = ( data ) => {

	scenes.hash = data.hash
	scenes.scenes = data.scenes

	for ( let app in nodes.debug.scenes ) {
		nodes.debug.scenes[ app ].scenes.forEach( node => {
			let scene_match = 		node.dataset.scene === data.scenes[ app ].scene
			let subscene_match = 	node.dataset.subscene === data.scenes[ app ].subscene
			let action = scene_match && subscene_match ? 'add' : 'remove'
			node.classList[ action ]( 'selected' )
		} )

		if ( app == 'hero' ) {
			nodes.debug.locations.forEach( node => {
				let loc_match = node.dataset.loc === data.scenes[ app ].location
				let action = loc_match ? 'add' : 'remove'
				node.classList[ action ]( 'selected' )	
			} )
		}
	}


	let is_backdrop = test_is_backdrop()
	let action = is_backdrop ? 'add' : 'remove'
	nodes.debug.backdrop.toggle.classList[ action ]( 'selected' )

	console.log( '✨ scene data', data.hash )

}



let test_is_backdrop = () => {
	let is_backdrop = false
	for ( let app in scenes.scenes ) {
		if ( scenes.scenes[ app ].scene === 'BACKDROP' ) is_backdrop = true		
	}
	return is_backdrop
}








let normalize_click = ( e ) => {

	if ( e.touches ) {
		let data = e.touches[ 0 ]
		data.path = [ data.target ]
		click_handler( e.touches[ 0 ] )
	}
	else {
		click_handler( e )
	}

}


let normalize_touchmouse_move = ( e ) => {

	if ( e.touches ) {
		let data = e.touches[ 0 ]
		data.path = [ data.target ]
		move_handler( e.touches[ 0 ] )
	}
	else {
		move_handler( e )
	}

}


let click_handler = ( e ) => {

	let submit = utils.walking_class_test( e.path[ 0 ], 'submit' )
	let select = utils.walking_class_test( e.path[ 0 ], 'response' )
	let range =  utils.walking_class_test( e.path[ 0 ], 'range-slider' )
	let question = utils.walking_class_test( e.path[ 0 ], 'question-module' )
	let location = utils.walking_class_test( e.path[ 0 ], 'location-select' )
	let question_select = utils.walking_class_test( e.path[ 0 ], 'question-selector' )
	let location_hero = utils.walking_class_test( e.path[ 0 ], 'location-option' )
	let scene_select = utils.walking_class_test( e.path[ 0 ], 'scene-option' )
	let qid = question && question.dataset ? question.dataset.qid : null
	let backdrop = utils.walking_class_test( e.path[ 0 ], 'backdrop-option' )


	// LOCATION, ACTIVE QUESTION SELECTOR & SCENE MANAGER

	if ( location ) {

		sync_location()

	}
	else if ( question_select ) {

		let qid = question_select.dataset.qid
		select_active_question( qid )

	}
	else if ( backdrop ) {

		let is_backdrop = test_is_backdrop()
		if ( is_backdrop ) {
			
			console.log( scenes.scenes )
			socket.emit( 'backdrop_off' )

		}
		else {

			socket.emit( 'backdrop_on')

		}

		// select_scene( 'side_l', 'BACKDROP', 'DEFAULT' )
		// select_scene( 'side_r', 'BACKDROP', 'DEFAULT' )
		// select_scene( 'hero', 'BACKDROP', 'DEFAULT' )
		// select_scene( 'conf', 'BACKDROP', 'DEFAULT' )

	}
	else if ( scene_select ) {

		let app_id = scene_select.dataset.app
		let scene = scene_select.dataset.scene
		let subscene = scene_select.dataset.subscene
		select_scene( app_id, scene, subscene )

	}
	else if ( location_hero ) {

		let loc = location_hero.dataset['loc']
		socket.emit( 'to_loc_request', loc )

	}


	// QUESTION & RESPONSE submit

	if ( !qid ) {
		clear_options()
		return
	}

	if ( submit ) {
		
		submit_response( qid )

	}
	else if ( range ) {

		select_range( qid, range.value, Number( range.dataset.rangemax ) )

	}
	else if ( select ) {

		let i = Number( select.dataset.responseindex )
		select_option( qid, i )

	}

}


let move_handler = ( e ) => {

	let drag = e.buttons === 1

	if ( !drag ) return

	let range = utils.walking_class_test( e.path[ 0 ], 'range-slider' )
	let question = utils.walking_class_test( e.path[ 0 ], 'question-module' )

	let qid = question && question.dataset ? question.dataset.qid : null

	if ( range ) {
		select_range( qid, range.value, Number( range.dataset.rangemax ) )
	}

}


let select_active_question = ( qid ) => {

	socket.emit( 'update_active_question', qid )

}


let select_scene = ( app_id, scene, subscene ) => {

	socket.emit( 'scene_update', { 'app_id': app_id, 'scene': scene, 'subscene': subscene } )

}


let select_option = ( qid, i ) => {

	clear_options()

	status.qid = qid
	status.i = i

	nodes.debug.questions[ qid ].submit.classList.add( 'ready' )
	nodes.debug.questions[ qid ].responses[ i ].classList.add( 'selected' )

}


let select_range = ( qid, value, max ) => {

	let min = ( max === 100 ) ? 0 : 1
	let i = Math.round( utils.mix( value / 100, min, max, true ) )
	i = ( max === 100 ) ? i : i - 1

	if ( status.qid !== qid ) clear_options()

	status.qid = qid
	status.i = i

	let str = ( max === 100 ) ? i + '%' : ( i + 1 )
	nodes.debug.questions[ qid ].label.innerHTML = str

	nodes.debug.questions[ qid ].submit.classList.add( 'ready' )

}


let submit_response = ( qid ) => {

	if ( qid !== status.qid ) {
		console.log( 'error, somehow qids are mismatched' )
		return
	}
	if ( nodes.debug.questions[ qid ].module.dataset.location !== 'GLOBAL' ) {
		// local question ignores global location dropdown...
		status.location = my_location
	}

	socket.emit( 'response_submit', status )

	clear_options()

}


let clear_options = ( scope ) => {

	for ( let qid in nodes.debug.questions ) {

		if ( scope === undefined || scope === qid ) {

			let q = nodes.debug.questions[ qid ]
			if ( q.responses ) {
				q.responses.forEach( r => { r.classList.remove( 'selected' ) } ) 
			}
			if ( q.submit ) {
				q.submit.classList.remove( 'ready' )
			}
			if ( q.range ) {
				q.range.value = 50
				q.label.innerHTML = '--'
			}

		}

	}

}


let sync_location = () => {

	status.location = nodes.debug.location_select.value

	for ( let qid in nodes.debug.questions ) {

		let q = nodes.debug.questions[ qid ]
		let l = q.module.dataset.location

		if ( l === 'GLOBAL' || l === my_location ) {

			q.module.classList.add( 'visible' )
		
		}
		else {

			q.module.classList.remove( 'visible' )

		}

	}

}