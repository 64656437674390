import { gsap, CustomEase, SteppedEase, DrawSVGPlugin, Power2, Power1, Power0 } from 'gsap'
gsap.registerPlugin( CustomEase, SteppedEase, DrawSVGPlugin )

import * as utils from 'utils'
import { nodes } from 'nodes'
import * as poll from './../sections/poll'

export default class View {

	constructor( root_node, data ) {

		this.nodes = {
			view: root_node
		}

		this._tween_in_tl = null
		this._tween_out_tl = null

		this.visible = false
		this.active = false

	}


	tween_in_start() {

		this.visible = true

		this.nodes.view.classList.add( 'visible' )

		if ( this.is_question ) {

			if ( this.submitted ) {

				this.nodes.data_wrap.classList.add( 'visible' )

				this.nodes.submit_wrap.classList.remove( 'visible' )
				this.nodes.response_wrap.classList.remove( 'visible' )

			}
			else {

				this.nodes.response_wrap.classList.add( 'visible' )

				this.nodes.data_wrap.classList.remove( 'visible' )
				this.nodes.submit_wrap.classList.remove( 'visible' )

			}

		}

	}


	tween_in_complete() {
		if( this._tween_in_tl ) {
			this._tween_in_tl.kill()
		}
		this._tween_in_tl = null
		this.active = true
		this.set_interactive( true )
	}


	tween_out_start() {
		this.active = false
		this.set_interactive( false )
		clearTimeout( this._show_next_button_timer )
		poll.hide_next_button()
	}


	tween_out_complete() {
		this.visible = false
		this.nodes.view.classList.remove( 'visible' )
		if ( this._tween_out_tl ) {
			this._tween_out_tl.kill()
		}
		this._tween_out_tl = null
	}


	set_interactive( val ) {
		let action = val ? 'add' : 'remove'
		nodes.poll.section.classList[ action ]( 'interactive' )
	}


}