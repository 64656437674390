import Vector2 from './Vector2'
import world from './../../sections/world'

export default class Circle {

	constructor( data ) {

		// POSITION
		this._position = ( data.position && data.position.isVector2 ) 
			? data.position
			: new Vector2( 0, 0 )

		// RADIUS
		this._radius = ( data.radius !== undefined && typeof data.radius === 'number' )
			? data.radius
			: 10.0

		// STROKE WIDTH
		this._strokeWidth = ( 
			data.strokeWidth !== undefined && 
			typeof data.strokeWidth === 'number'
		)
			? data.strokeWidth
			: 0

		// FILL COLOR
		this._fillColor = ( data.fillColor !== undefined )
			? data.fillColor
			: "none"

		// STROKE COLOR
		this._strokeColor = ( data.strokeColor !== undefined )
			? data.strokeColor
			: "#000"

		// STROKE JOIN
		this._strokeJoin = ( data.strokeJoin === 'round' || data.strokeJoin === 'bevel' )
			? data.strokeJoin
			: 'none'


		// DOM NODE
		this._node = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'circle'
		)

		// APPLY INTERNALS TO NODE
		this.update()

	}


	// - - - GETTERS

	get position() 		{ return this._position }

	get x() 			{ return this._position.x }

	get y() 			{ return this._position.y }	

	get radius() 		{ return this._radius }

	get strokeWidth() 	{ return this._strokeWidth }

	get fillColor() 	{ return this._fillColor }

	get strokeColor() 	{ return this._strokeColor }

	get node() 			{ return this._node }

	get boundingBox() { 
		return {
			x: this._x,
			y: this._y,
			w: this._w,
			h: this._h
		}
	}



	// - - - SETTERS

	set x( val ){
		this._position.x = val
	}

	set y( val ){
		this._position.y = val
	}

	set position( val ) {
		this._position.x = val.x
		this._position.y = val.y
	}

	set radius( val ) {
		this._radius = val
	}

	set fillColor( val ) { 
		this._fillColor = val
	}

	set strokeColor( val ) { 
		this._strokeColor = val
	}



	// - - - HELPERS

	update() {

		this.updateBoundingBox()

		this._node.setAttribute( 'cx', this._position.x )
		this._node.setAttribute( 'cy', this._position.y )
		this._node.setAttribute( 'r', this._radius )

		this._node.setAttribute( 'fill', this._fillColor )
		this._node.setAttribute( 'stroke', this._strokeColor )
		this._node.setAttribute( 'stroke-width', this._strokeWidth )
		this._node.setAttribute( 'stroke-linejoin', this._strokeJoin )
	}
	
	updateBoundingBox() {
		this._x = this._position.x - this._radius
		this._y = this._position.y - this._radius
		this._w = this._radius * 2.0
		this._h = this._radius * 2.0
	}

	destroy() {
		this._node.remove()
	}

}
