// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Imports

import { gsap, CustomEase, SteppedEase, Draggable, DrawSVGPlugin, Power2, Power1, Power0, Circ, SplitText, InertiaPlugin } from 'gsap'
gsap.registerPlugin( CustomEase, SteppedEase, DrawSVGPlugin, Draggable, InertiaPlugin, SplitText )


import * as svg from './../model/svg'
import { build as nodes_build } from './../components/nodes'
import * as world from './world'
import { nodes } from 'nodes'
import * as utils from 'utils'
import * as content from './../components/content'
import * as palette from './../components/palette'
import * as controller from './../app/controller'

import Page from './../model/Page'
import Binary from './../model/Binary'
import Range from './../model/Range'
import Multiplechoice from './../model/Multiplechoice'
import Beacon from './../model/Beacon'
import template from './../../templates/poll.dot'





// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - State

export let status = {
	init: false,
	cur_module_index: null,
	cur_module: null,
	prev_module: null
}



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  Build Page


export let view_modules = []		// including beacon final view
export let question_modules = []	// just the question views

export let build = async () => {

	status.init = true

	palette.build()


	// COLLECT nodes within template
	nodes_build( 'poll' )

	// INSTANTIATE all question views
	content.all.POLL.QUESTIONS.forEach( q => {

		let instance
		if ( q.type === 'BINARY' ) {
			instance = new Binary( 
				utils.qs( `.section.poll .question-module.id-${ q.id }` ), q
			)
		}
		else if ( q.type === 'RANGE' ) {
			instance = new Range( 
				utils.qs( `.section.poll .question-module.id-${ q.id }` ), q
			)
		}
		else if ( q.type === 'MULTIPLECHOICE' ) {
			instance = new Multiplechoice( 
				utils.qs( `.section.poll .question-module.id-${ q.id }` ), q
			)
		}

		view_modules.push( instance )
		question_modules.push( instance )

	} )

	// INSTANTIATE final beacon view
	let beacon = new Beacon(
		utils.qs( `.section.poll .question-module.beacon` ), {}
	)
	view_modules.push( beacon )


	// ADD top level event listeners
	nodes.poll.nav_wrap.addEventListener( 'click', nav_handler )
	nodes.poll.submit_button.addEventListener( 'click', submit_handler )
	// nodes.poll.location_bar.addEventListener( 'click', location_handler )

	// BEGIN poll
	status.cur_module_index = 0
	status.cur_module = view_modules[ status.cur_module_index ]

	// DEV LOG content
	// console.log( 'Poll Content:', content.all.POLL )
	// console.log( 'Modules:', view_modules )
	
	// Animate in
	setTimeout( () => {

		status.cur_module.tween_in()
		sync_nav()
		sync_submit_button()
		sync_location_bar()
		
		requestAnimationFrame( () => { resize_handler() } )

	}, 1000 )

}




// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  Navigation Bar

let nav_handler = ( event ) => {

	let e = event
	if ( !e.path ) {
		e.path = [ event.target ]
	}

	let progress_module = utils.walking_class_test( e.path[ 0 ], 'progress-module' )
	let button_back 	= utils.walking_class_test( e.path[ 0 ], 'button-back' )
	let button_next 	= utils.walking_class_test( e.path[ 0 ], 'button-next' )

	if ( progress_module ) {

		let new_index = Number( progress_module.dataset['index'] )
		new_index = utils.clamp( new_index, 0, view_modules.length - 1 )
		nav_to( new_index )

	}
	else if ( button_back ) {

		let new_index = status.cur_module_index - 1
		new_index = utils.clamp( new_index, 0, view_modules.length - 1 )
		nav_to( new_index )

	}
	else if ( button_next ) {

		let new_index = status.cur_module_index + 1
		new_index = utils.clamp( new_index, 0, view_modules.length - 1 )
		nav_to( new_index )

	} 

}


let nav_to = async ( index ) => {

	if ( typeof index !== 'number' ) {
		console.log( 'Error: cannot nav to module with index of ', index )
		return
	}
	else if ( 0 > index || index >= view_modules.length ) {
		console.log( 'Error: cannot nav to module, index out of range', index )
		return
	}
	else if ( index === status.cur_module_index ) {
		// console.log( 'Error: cannot nav to module, already on it' )
		return
	}

	status.prev_module = view_modules[ status.cur_module_index ]
	status.prev_module_index = status.cur_module_index
	status.cur_module_index = index
	status.cur_module = view_modules[ index ]

	if ( status.cur_module.is_question ) {
		status.cur_module.reset_response()
		status.cur_module.reset_dataviz()
	}
	
	sync_nav()
	sync_submit_button()
	sync_location_bar()

	await status.prev_module.tween_out()
	await status.cur_module.tween_in()

}


let sync_nav = () => {

	let i = status.cur_module_index

	// SYNC progress bar states
	nodes.poll.nav_progress_modules.forEach( ( m, j ) => {
		let q = question_modules[ j ]
		if ( q.submitted ) {
			m.classList.add( 'responded' )
			let c = palette.get( q.response_index, 'light', q.type, q.range_max )
			let inner = utils.qs( '.inner', m )
			inner.style.backgroundColor = c

		}
		else {
			m.classList.remove( 'responded' )
		}

		let action = ( j === i ) ? 'add' : 'remove'
		m.classList[ action ]( 'active' )
	} )

	// BEACON final view, different nav layout
	if ( i === view_modules.length - 1 ) {
		nodes.poll.nav_wrap.classList.add( 'beacon' )
	}
	else {
		nodes.poll.nav_wrap.classList.remove( 'beacon' )
	}

	// PREVENT back button when on first question
	if ( i === 0 ) {
		nodes.poll.nav_wrap.classList.add( 'first-module' )
	}
	else {
		nodes.poll.nav_wrap.classList.remove( 'first-module' )	
	}

	// PREVENT nav to beacon view if no questions answered yet
	let responses = 0
	question_modules.forEach( x => { responses = responses + ( x.submitted ) ? 1 : 0 } )
	if ( i === question_modules.length - 1 && responses === 0 ) {
		nodes.poll.nav_wrap.classList.add( 'last-module' )
	}
	else {
		nodes.poll.nav_wrap.classList.remove( 'last-module' )	
	}


}


export let show_next_button = () => {

	nodes.poll.nav_button_next.classList.remove( 'minimal' )
	nodes.poll.nav_button_back.classList.remove( 'minimal' )

}

export let hide_next_button = () => {

	nodes.poll.nav_button_next.classList.add( 'minimal' )
	nodes.poll.nav_button_back.classList.add( 'minimal' )

}


export let beacon_visited = false

export let visiting_beacon = () => {

	beacon_visited = true

}




// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Submit Button


let submit_showing = false

let submit_handler = async ( event ) => {

	status.cur_module.submit()

	await submit_button_submit_tween()

	sync_nav()

	submit_button_tween_out()

}


export let sync_submit_button = () => {


	// SHOW / HIDE button

	if ( 
		status.cur_module.submitted || 
		status.cur_module_index === view_modules.length - 1
	) {

		if ( submit_showing ) submit_button_tween_out()

	}
	else {

		if ( !submit_showing ) submit_button_tween_in()

	}


	// READY / NOT-READY state

	if ( submit_showing ) {

		if ( status.cur_module.response_index === null ) {

			nodes.poll.submit_button.classList.remove( 'active' )

			gsap.set(
				nodes.poll.submit_button.style,
				{ backgroundColor: "#152026" }
			)

		}
		else {

			let color = palette.get( 
				status.cur_module.response_index, 
				'light', 
				status.cur_module.type,
				status.cur_module.range_max
			)

			gsap.to(
				nodes.poll.submit_button.style,
				0.25,
				{ backgroundColor: color }
			)

			nodes.poll.submit_button.classList.add( 'active' )

		}

	}


}


let submit_button_submit_tween_tl = null

let submit_button_submit_tween = async () => {


	if ( submit_button_submit_tween_tl ) {
		submit_button_submit_tween_tl.pause()
		submit_button_submit_tween_tl.kill()
		submit_button_submit_tween_tl = null
	}

	await new Promise( resolve => {

		submit_button_submit_tween_tl = gsap.timeline( {
			paused: true,
			onComplete: () => {

				resolve()

			}
		} )

		submit_button_submit_tween_tl.add( gsap.to( 
			nodes.poll.submit_button,
			0.25,
			{
				scale: 0.75,
				rotate: 15,
				ease: Power2.easeOut
			}
		), 0.0 )

		submit_button_submit_tween_tl.add( gsap.to( 
			nodes.poll.submit_button,
			0.25,
			{
				scale: 1.0,
				rotate: 0,
				ease: Power2.easeOut
			}
		), 0.25 )

		submit_button_submit_tween_tl.add( gsap.fromTo( 
			nodes.poll.submit_svg_line,
			0.4,
			{
				drawSVG: "0% 100%"
			},
			{
				drawSVG: "100% 100%",
				ease: Power2.easeIn
			}
		), 0.5 )


		submit_button_submit_tween_tl.play()

	} )

}






let submit_button_tween_out_tl = null

let submit_button_tween_out = async () => {

	if ( submit_button_tween_in_tl ) {
		submit_button_tween_in_tl.pause()
		submit_button_tween_in_tl.kill()
		submit_button_tween_in_tl = null
	}

	if ( submit_button_tween_out_tl ) {
		submit_button_tween_out_tl.pause()
		submit_button_tween_out_tl.kill()
		submit_button_tween_out_tl = null
	}

	submit_showing = false

	await new Promise( resolve => {

		submit_button_tween_out_tl = gsap.timeline( {
			paused: true,
			onComplete: () => {
				nodes.poll.submit_button.classList.remove( 'visible' )
				submit_button_tween_out_tl.pause()
				submit_button_tween_out_tl.kill()
				submit_button_tween_out_tl = null
			},
			onStart: () => {
				
			}
		} )

		submit_button_tween_out_tl.add( gsap.to(
			nodes.poll.submit_svg_line,
			0.4,
			{
				drawSVG: "100% 100%",
				ease: Power2.easeIn
			}
		), 0.0 )

		submit_button_tween_out_tl.add( gsap.to(
			nodes.poll.submit_button,
			0.25,
			{
				scale: 0,
				transformOrigin: "50% 50%"
			}
		), 0.2 )

		submit_button_tween_out_tl.play()

	} )

}



let submit_button_tween_in_tl = null

let submit_button_tween_in = async () => {

	if ( submit_button_tween_out_tl ) {
		submit_button_tween_out_tl.pause()
		submit_button_tween_out_tl.kill()
		submit_button_tween_out_tl = null
	}

	if ( submit_button_tween_in_tl ) {
		submit_button_tween_in_tl.pause()
		submit_button_tween_in_tl.kill()
		submit_button_tween_in_tl = null
	}

	submit_showing = true

	await new Promise( resolve => {

		submit_button_tween_in_tl = gsap.timeline( {
			paused: true,
			onComplete: () => {
				submit_button_tween_in_tl.pause()
				submit_button_tween_in_tl.kill()
				submit_button_tween_in_tl = null
			},
			onStart: () => {
				nodes.poll.submit_button.classList.add( 'visible' )
			}
		} )

		submit_button_tween_in_tl.add( gsap.fromTo(
			nodes.poll.submit_button,
			0.25,
			{
				scale: 0,
			},
			{
				scale: 1,
				transformOrigin: "50% 50%"
			}
		), 0.25 )

		submit_button_tween_in_tl.add( gsap.fromTo(
			nodes.poll.submit_svg_line,
			0.4,
			{
				drawSVG: "0% 0%"
			},
			{
				drawSVG: "0% 100%",
				ease: Power2.easeOut
			}
		), 0.4 )

		submit_button_tween_in_tl.play()

	} )

}




// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Locations Bar



let select_loc_handler = ( index ) => {

	// let i = loc_status.count - 1 - index

	if ( nodes.poll.locations[ index ] ) {

		let el = nodes.poll.locations[ index ]

		let loc = el.dataset[ 'location' ]

		loc = ( loc === 'ALL' ) ? 'SUMMARY' : loc
		
		// let i_in_view = loc_status.locs.indexOf( status.cur_module.loc_in_view )

		if ( loc !== status.cur_module.loc_in_view ) {

			status.cur_module.tween_loc( loc )

			// UPDATE state of buttons based on location in data view
			nodes.poll.locations.forEach( l => {
				if ( l.dataset[ 'location' ] == status.cur_module.loc_in_view ) {
					l.classList.add( 'active' )
				}
				else {
					l.classList.remove( 'active' )	
				}
			} )

		}

	}

}

export let sync_location_bar = () => {

	status.cur_module_index === view_modules.length - 1

	// FIX sometimes empty state on first module load
	let empty = !( 
		nodes.poll.location_bar.classList.contains( 'local' ) || 
		nodes.poll.location_bar.classList.contains( 'global' )
	)
	if ( empty ) {

		if ( status.cur_module.location === 'GLOBAL' ) {	
			nodes.poll.location_bar.classList.add( 'global' )
		}
		else {
			nodes.poll.location_bar.classList.add( 'local' )	
		}
	}

	// HIDE / SHOW proper local or global group
	if ( status.cur_module.location === 'GLOBAL' ) {

		location_bar_to_global_tween()	

	}
	else {
		
		location_bar_to_local_tween()

	}

	// HIDE / SHOW location bar 
	if ( status.cur_module.submitted ) {

		location_bar_tween_in()

	}
	else {

		location_bar_tween_out()

	}

}

let location_bar_tween_out_tl = null

let location_bar_tween_out = async () => {

	if ( location_bar_tween_in_tl ) {
		location_bar_tween_in_tl.pause()
		location_bar_tween_in_tl.kill()
		location_bar_tween_in_tl = null
	}

	if ( location_bar_tween_out_tl ) {
		location_bar_tween_out_tl.pause()
		location_bar_tween_out_tl.kill()
		location_bar_tween_out_tl = null
	}

	await new Promise( resolve => {

		location_bar_tween_out_tl = gsap.timeline( {
			paused: true,
			onComplete: () => {
				location_bar_tween_out_tl.pause()
				location_bar_tween_out_tl.kill()
				location_bar_tween_out_tl = null
			},
			onStart: () => {
				nodes.poll.location_bar.classList.remove( 'active' )
			}
		} )

		location_bar_tween_out_tl.add( gsap.to(
			nodes.poll.location_bar,
			0.25,
			{
				opacity: 0,
				y: "100%"
			}
		), 0.0 )

		location_bar_tween_out_tl.play()

	} )

}



let location_bar_tween_in_tl = null

let location_bar_tween_in = async () => {

	if ( location_bar_tween_out_tl ) {
		location_bar_tween_out_tl.pause()
		location_bar_tween_out_tl.kill()
		location_bar_tween_out_tl = null
	}

	if ( location_bar_tween_in_tl ) {
		location_bar_tween_in_tl.pause()
		location_bar_tween_in_tl.kill()
		location_bar_tween_in_tl = null
	}

	await new Promise( resolve => {

		location_bar_tween_in_tl = gsap.timeline( {
			paused: true,
			onComplete: () => {
				nodes.poll.location_bar.classList.add( 'active' )
				location_bar_tween_in_tl.pause()
				location_bar_tween_in_tl.kill()
				location_bar_tween_in_tl = null
			},
			onStart: () => {

			}
		} )

		location_bar_tween_in_tl.add( gsap.to(
			nodes.poll.location_bar,
			0.25,
			{
				opacity: 1,
				y: "0%"
			}
		), 0.25 )

		location_bar_tween_in_tl.play()

	} )

}


let location_bar_to_global_tween_tl = null

let location_bar_to_global_tween = () => {

	if ( location_bar_to_global_tween_tl ) {
		location_bar_to_global_tween_tl.pause()
		location_bar_to_global_tween_tl.kill()
		location_bar_to_global_tween_tl = null
	}

	location_bar_to_global_tween_tl = gsap.timeline( {
		paused: true,
		onComplete: () => {
		
			if ( location_bar_to_global_tween_tl ) {
				location_bar_to_global_tween_tl.pause()
				location_bar_to_global_tween_tl.kill()
				location_bar_to_global_tween_tl = null
			}

		}
	} )


	if ( nodes.poll.location_bar.classList.contains( 'local' ) ) {

		// tween out local first
		location_bar_to_global_tween_tl.add( gsap.to(
			nodes.poll.location_bar_local,
			0.2,
			{
				opacity: 0,
				onComplete: () => {

					nodes.poll.location_bar.classList.remove( 'local' )

					gsap.set( 
						nodes.poll.location_bar_global,
						{
							opacity: 0
						}
					)

					nodes.poll.location_bar.classList.add( 'global' )

					build_loc_draggable()

				}
			}
			
		), 0 )

	}
	else {

		location_bar_to_global_tween_tl.add( gsap.to(
			nodes.poll.location_bar_global,
			0.2,
			{
				opacity: 0,
				onComplete: () => {

					build_loc_draggable()

				}
			}
		), 0 )

	}


	location_bar_to_global_tween_tl.add( gsap.to(
		nodes.poll.location_bar_global,
		0.2,
		{
			opacity: 1
		}
	), 0.2 )



	location_bar_to_global_tween_tl.play()

}




let location_bar_to_local_tween_tl = null

let location_bar_to_local_tween = () => {

	if ( location_bar_to_local_tween_tl ) {
		location_bar_to_local_tween_tl.pause()
		location_bar_to_local_tween_tl.kill()
		location_bar_to_local_tween_tl = null
	}

	location_bar_to_local_tween_tl = gsap.timeline( {
		paused: true,
		onComplete: () => {
		
			if ( location_bar_to_local_tween_tl ) {
				location_bar_to_local_tween_tl.pause()
				location_bar_to_local_tween_tl.kill()
				location_bar_to_local_tween_tl = null
			}

		}
	} )


	if ( nodes.poll.location_bar.classList.contains( 'local' ) ) {

		// no draggable to reset, no need to fade out and back...		

	}
	else {

		location_bar_to_local_tween_tl.add( gsap.to(
			nodes.poll.location_bar_global,
			0.2,
			{
				opacity: 0,
				onComplete: () => {

					nodes.poll.location_bar.classList.remove( 'global' )

					gsap.set( 
						nodes.poll.location_bar_local,
						{
							opacity: 0
						}
					)

					nodes.poll.location_bar.classList.add( 'local' )

				}
			}
		), 0 )


		location_bar_to_local_tween_tl.add( gsap.to(
			nodes.poll.location_bar_local,
			0.2,
			{
				opacity: 1
			}
		), 0.2 )

	}


	location_bar_to_local_tween_tl.play()

}












// - - - Location Scroller

let loc_scroll_tls = []

let loc_status = {
	count: 1
}

let build_loc_draggable = () => {


	if ( loc_scroll_tls ) {
		loc_scroll_tls.forEach( ( tl, i ) => {
			loc_scroll_tls[ i ].kill()
			loc_scroll_tls[ i ] = null
		} )
		loc_scroll_tls = []
	}
	else {
		loc_scroll_tls = []
	}

	loc_status.locs = [ 'SUMMARY' ].concat( content.all.POLL.locations_master )

	loc_status.count = nodes.poll.locations.length
	loc_status.module_w = nodes.poll.locations[ 0 ].clientWidth
	loc_status.total_w = ( loc_status.count ) * loc_status.module_w

	loc_status.start_x = -loc_status.module_w * ( loc_status.count * 0.5 - 0.5 )
	loc_status.end_x = loc_status.start_x + ( loc_status.count - 1 ) * loc_status.module_w


	let spread_x = 200
	let twist = 45
	let scale = 0.7

	// - - - TWEENS per row

	nodes.poll.locations.forEach( ( loc, i ) => {

		let tl = gsap.timeline( {
			paused: true
		} )

		// slide
		tl.add( gsap.fromTo(
			loc, 0.5,
			{
				x: spread_x,
			},
			{
				x: 0,
				ease: Power2.easeIn
			}
		), 0 )

		tl.add( gsap.to(
			loc, 0.5,
			{
				x: -spread_x,
				ease: Power2.easeOut
			}
		), 0.5 )


		
		// scale
		tl.add( gsap.fromTo(
			loc, 0.1,
			{
				scale: scale,
			},
			{
				scale: 1,
				transformOrigin: "50% 50%",
				ease: Power2.easeInOut
			}
		), 0.4 )

		tl.add( gsap.to(
			loc, 0.1,
			{
				scale: scale,
				ease: Power2.easeInOut
			}
		), 0.5 )


		// opacity
		tl.add( gsap.fromTo(
			loc, 0.5,
			{
				opacity: 0,
			},
			{
				opacity: 1,
				ease: Power2.easeIn
			}
		), 0.0 )

		tl.add( gsap.to(
			loc, 0.5,
			{
				opacity: 0,
				ease: Power2.easeOut
			}
		), 0.5 )


		// twist
		tl.add( gsap.fromTo(
			loc, 0.5,
			{
				rotateY: -twist,
			},
			{
				rotateY: 0,
				ease: Power2.easeIn
			}
		), 0 )

		tl.add( gsap.to(
			loc, 0.5,
			{
				rotateY: twist,
				ease: Power2.easeOut
			}
		), 0.5 )


		loc_scroll_tls.push( tl )

	} )


	// - - - PROXIED DRAGGABLE

	if ( loc_status.scroll_proxy ) {
		loc_status.scroll_proxy.remove()
		loc_status.scroll_proxy = null
	}

	if ( loc_status._draggable && loc_status._draggable[ 0 ] ) {
		loc_status._draggable[ 0 ].kill()
		loc_status._draggable[ 0 ] = null
		loc_status._draggable = null
	}

	loc_status.loc_bar_width = nodes.poll.location_bar_global.clientWidth

	loc_status.scroll_proxy = document.createElement( 'div' )
	loc_status._draggable = Draggable.create( 
		loc_status.scroll_proxy, 
		{
			type: 'x',
			lockAxis: true,
			trigger: nodes.poll.location_bar_global,
			inertia: true,
			edgeResistance: 1,
			throwResistance: 3000,
			minDuration: 0.5,
			maxDuration: 0.5,
			bounds: {
				minX: 0,
				maxX: loc_status.total_w 
			},
			snap: ( x ) => { return loc_snap_helper( x ) },
			onPress: () => {
				if ( loc_status._scroll_to_tl ) {
					loc_status._scroll_to_tl.pause()
					loc_status._scroll_to_tl.kill()
					loc_status._scroll_to_tl = null
				}
			},
			onDragStart: ( e ) => {
				loc_status.drag_start_pos = loc_status._draggable[ 0 ].x
			},
			onDrag: () => { 
				loc_update_scroll()
			},
			onThrowUpdate: () => { 
				loc_update_scroll()
			},
			onClick: ( e ) => {

				let dir = 0
				if ( e.layerX / loc_status.loc_bar_width > 0.52 ) dir = 1
				if ( e.layerX / loc_status.loc_bar_width < 0.48 ) dir = -1

				let i_in_view = loc_status.locs.indexOf( status.cur_module.loc_in_view )

				let i = utils.clamp( i_in_view + dir, 0, loc_status.count - 1 )
				
				if ( dir !== 0 ) {

					let x = loc_status.total_w / ( loc_status.count - 1 )
	
					x *= ( loc_status.count - 1 - i )


					loc_status._scroll_to_tl = gsap.to(
						loc_status._draggable[ 0 ].target,
						0.25,
						{
							x: x,
							onUpdate: () => {
								loc_status._draggable[ 0 ].update()
								loc_update_scroll()
							}
						}
					)

				}

			},
			onDragEnd: () => {

			},
			onRelease: () => {

			},
			onThrowComplete: () => {
				// this.finish_range()
			}
		}
	)

	gsap.set( 
		loc_status._draggable[ 0 ].target, 
		{ x: loc_status.total_w }
	)

	loc_status._draggable[ 0 ].applyBounds()
	loc_status._draggable[ 0 ].update()

	loc_update_scroll( true )


}



let loc_update_scroll = ( block_paint ) => {

	let n = 
		( loc_status._draggable[ 0 ].x - loc_status._draggable[ 0 ].minX ) /
		( loc_status._draggable[ 0 ].maxX - loc_status._draggable[ 0 ].minX )

	loc_status.scroll_norm = 1.0 - utils.clamp( n, 0, 1 )

	let index_tween = loc_status.scroll_norm * ( loc_status.count - 1 )
	
	let index = Math.round( index_tween )

	let i_in_view = loc_status.locs.indexOf( status.cur_module.loc_in_view )

	if ( !block_paint && index !== i_in_view ) {
		select_loc_handler( index )
	}

	loc_scroll_tls.forEach( ( tl, i ) => {

		let rel_index = ( index_tween - i )

		let rel_norm = utils.normalize( rel_index, -5, 5, true )

		tl.progress( rel_norm )

	} )

}


let loc_snap_helper = ( end_value ) => {

	let norm =
		( end_value - loc_status._draggable[ 0 ].minX ) /
		( loc_status._draggable[ 0 ].maxX - loc_status._draggable[ 0 ].minX )

	norm = utils.clamp( norm, 0, 1 )

	let index = Math.round( norm * ( loc_status.count - 1 ) )


	// - - - BUMP, give some extra oomph to a small but intentional swipe

	let bump_threshold = 6

	let i_in_view = loc_status.locs.indexOf( status.cur_module.loc_in_view )

	if ( 
		Math.abs( loc_status.drag_start_pos - end_value ) > bump_threshold 
		&&
		index === i_in_view
	) {
		
		let dir = ( end_value > loc_status.drag_start_pos )
			? 1
			: -1

		index += dir

		index = utils.clamp( index, 0, loc_status.count - 1 )

	}


	let x = loc_status.total_w / ( loc_status.count - 1 )
	
	x *= index

	return x
	
}




















// - - - - - - - - - - - - - - - - - - - - - - - - - - - - Sync Data with Server


export let sync_data = ( data ) => {

	// console.log( 'received data update for Question:', data )

	question_modules.forEach( q => {
		if ( q.id === data.id ) {
			q.sync_data( data.data )
		}
	} )



}






// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Update Loop

export let update = () => {

	view_modules.forEach( m => {

		m.update()
	
	} )



}

export let submit_position_cache = new svg.Vector2( 0, 0 )

export let resize_handler = () => {

	if ( !status.init ) return

	let pos = {
		x: nodes.poll.submit_button.offsetLeft + nodes.poll.submit_button.clientWidth * 0.5,
		y: nodes.poll.submit_button.offsetTop + nodes.poll.submit_button.clientHeight * 0.5
	}

	if ( pos.x !== 0 && pos.y !== 0 ) {
		submit_position_cache.x = pos.x
		submit_position_cache.y = pos.y
	}

	view_modules.forEach( q => {

		q.resize()

		if ( q.is_question ) {

			q.bg_rings.dark.radius = submit_position_cache.length
			q.bg_rings.light.radius = submit_position_cache.length
			q.fg_rings.main.radius = submit_position_cache.length
		
		}

	} )

}











// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Register Page

new Page( {

	path: 'poll',

	instance: true,

	template: template,

	get_data: ( query ) => {
		return content.all.POLL
	},

	parent: false,

	on_load: build

} )
