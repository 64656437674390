export default () => {

	let usr = navigator.userAgent
	let is_mobile = usr.match(/Android/i) || usr.match(/BlackBerry/i) || usr.match(/iPhone/i) || usr.match(/iPad/i) || usr.match(/iPod/i) || usr.match(/iPhone|iPad|iPod/i) || usr.match(/IEMobile/i)
	let is_mobile_safari = usr.match(/iPhone/i) || usr.match(/iPad/i) || usr.match(/iPod/i) || usr.match(/iPhone|iPad|iPod/i)
	return {
		is_mobile: is_mobile,
		is_safari: is_mobile_safari
	}

}