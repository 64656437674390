import { gsap, CustomEase, SteppedEase, DrawSVGPlugin, Power2 } from 'gsap'
gsap.registerPlugin( CustomEase, SteppedEase, DrawSVGPlugin )

import { state, reset_scroll } from './../app/controller'
import * as utils from './utils'
import { nodes } from './nodes'
import * as lazyload from './lazyload'
import * as navigation from './navigation'
import * as world from './../sections/world' 


// - - - Default & Custom transitions between pages - - - //

let eases = {
	zip_out: CustomEase.create("custom", "M0,0 C0.3,0 0.3,1 1,1"),
	zip_in: CustomEase.create("custom", "M0,0,C0.4,0,0.2,1,1,1")
}

let config = {
	views: [ null, 'home', 'page1', 'page2' ]
}

export let status = {
	transitioning: false,
	active_tl: null
}

let t = 1 / 60



let transitions = {




	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  META

	map: ( _f, _t ) => {

		let f = ( _f ) ? _f.split( '/' )[ 0 ] : null
		let t = _t.split( '/' )[ 0 ]
		let action = `from_${ f }_to_${ t }`
		if ( transitions[ action ] !== undefined ) {
			return action
		}
		else {
			// console.error( 'cant find transition: "' + action + '". Using default' )
			return 'default'
		}
	},

	init: ( callback ) => {

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		} 

		let tl = new gsap.timeline({
			paused: true,
			onStart: () => {
				
			},
			onComplete: () => {

				lazyload.update()
				navigation.links_add()
					
				transitions.end()

				if ( callback ) callback()

			}
		} )

		status.transitioning = true
		status.active_tl = tl

		let tick = { x: 0 }
		tl.add( gsap.to( tick, 1 / 60, { x: 1 } ), 0 )

		return tl

	},

	end_early: () => {
		status.active_tl.progress( 1 ).pause()
	},

	end: () => {
		status.active_tl.invalidate()
		status.active_tl.clear()
		status.active_tl.kill()
		status.active_tl = null
		status.transitioning = false
	},

	start: () => {
		status.active_tl.play( 0 )
	},


	default: ( tl, _parent, _old, _new ) => {
		let temp = { x: 0 }
		tl.add( gsap.to( temp, 1 / 60, { x: 1 } ) )
	},



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - ANIMATE OUT AND IN

	from_null_to_home: ( tl, _parent, _old, _new, callback ) => {
			
		tweens.add_home_in( tl, 0 )

	},



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  INDIRECT LINKS

	from_home_to_poll: (tl, _parent, _old, _new, callback ) => {
		
		tweens.add_home_out( tl, 0 )
		tweens.add_poll_in( tl, t * 45 )
	
	}

}










let tweens = {

	add_home_in: ( tl, d ) => {

		tl.add( gsap.fromTo(
			nodes.home.section,
			t * 60,
			{
				opacity: 0
			},
			{
				opacity: 1
			}
		), d + t * 0 )

		tl.add( gsap.fromTo(
			nodes.world_wrap,
			t * 20,
			{
				opacity: 0
			},
			{
				opacity: 1
			}
		), d + t * 0 )

		

	},

	add_home_out: ( tl, d ) => {

		tl.add( gsap.fromTo(
			nodes.home.section,
			t * 60,
			{
				opacity: 1
			},
			{
				opacity: 0
			}
		), d + t * 0 )

	},

	add_poll_in: ( tl, d ) => {

		tl.add( gsap.fromTo(
			nodes.poll.section,
			t * 20,
			{
				opacity: 0
			},
			{
				opacity: 1
			}
		), d + t * 0 )

	}


}


























export { transitions as default }