export default class Vector2 {

	constructor( x, y ) {

		this._x = ( x ) ? x : 0
		this._y = ( y ) ? y : 0

	}

	get x() { return this._x }
	get y() { return this._y }

	get length() { return Math.sqrt( this._x * this._x + this._y * this._y ) }

	get isVector2() { return true }

	set x( val ) { this._x = val }
	set y( val ) { this._y = val }

}