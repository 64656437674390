import { gsap, CustomEase, SteppedEase, Draggable, DrawSVGPlugin, Power2, Power1, Power0, SplitText, InertiaPlugin } from 'gsap'
gsap.registerPlugin( CustomEase, SteppedEase, DrawSVGPlugin, Draggable, InertiaPlugin, SplitText )

import cookies from 'js-cookie'
import fetch from 'node-fetch'

import { build as nodes_build } from './../components/nodes'
import * as world from './world'
import { nodes } from './../components/nodes'
import * as utils from './../components/utils'
import * as controller from './../app/controller'

import Page from './../model/Page'
import template from './../../templates/home.dot'
import list_template from './../../templates/components/home-list.dot'
import empty_list_template from './../../templates/components/home-list-empty.dot'
import * as content from './../components/content'
import * as socket from './../components/socket'

import * as navigation from './../components/navigation'

let status = {
	init: false
}

let poll_buttons = []


export let build = () => {

	// COLLECT nodes within template
	nodes_build( 'home' )

	if ( !status.init ) {

		controller.sync_window_size_state()

		world.build_world()

	}
	else {

		window.location.reload()

	}

	nodes.home.location_select.addEventListener( 'input', () => { location_handler() } )

	nodes.home.begin_button.addEventListener( 'click', begin_handler )

	nodes.home.begin_button_alt.addEventListener( 'click', begin_alt_handler )

	nodes.home.back_button.addEventListener( 'click', back_handler )

	nodes.home.code_back_button.addEventListener( 'click', back_handler )

	nodes.home.list_wrap.addEventListener( 'click', list_handler, { passive: true } )

	nodes.home.code_input.addEventListener( 'input', entry_handler )

	nodes.home.code_begin_button_alt.addEventListener( 'click', begin_alt_handler )

	nodes.home.code_list_cta.addEventListener( 'click', focus_input )

	build_scrollable_list()

	apply_location()

	status.init = true


	if ( controller.show_list && controller.SESSION_LOCATION ) {

		to_list_view()
	
	}
	
}


let apply_location = () => {

	let cookie_val = cookies.get( 'facebook_beacons_partner_center_location' )
	location_handler( cookie_val )

}


let location_handler = ( _val ) => {

	let val = _val ? _val : nodes.home.location_select.value

	if ( content.locs.indexOf( val ) >= 0 ) {
		nodes.home.location_select.value = val
		let secure = ! String( window.location.host ).includes( 'localhost' )
		cookies.set( 'facebook_beacons_partner_center_location', val, { sameSite: 'Strict', secure: secure } )
		nodes.home.begin_button.classList.add('ready')
		nodes.home.location_select_wrap.classList.add('valid')
		controller.set_location( val )





		// DEV demo
		// world.hone_globe( controller.SESSION_LOCATION )




	}
	else {
		cookies.remove( 'facebook_beacons_partner_center_location' )
		nodes.home.begin_button.classList.remove('ready')
		nodes.home.location_select_wrap.classList.remove('valid')
		controller.set_location( false )
	}

}


let begin_handler = async () => {


	let url = process.env.CMS_HOST + process.env.CMS_POLL_EXISTS_API
	let body = JSON.stringify( { poll_id: controller.entry_point_initial, location: controller.SESSION_LOCATION } )
	let response = await fetch( 
		url, 
		{ 
			method: 'POST', 
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: body
		}
	)

	let json = await response.json()

	if ( json.valid === true ) {
		controller.set_poll( json )
	}

	let valid_pair = controller.validate_poll_location()

	if ( valid_pair ) {

		let json = await fetch_content()
	
		// ASSUME valid if not empty		
		if ( Object.keys( json ).length > 0 ) {

			content.store_poll_content( json )

			let data = await fetch_data( json )
			
			content.store_poll_data( data )

			navigation.link_click( null, 'poll/' + controller.POLL_ID )

		}
		else {

			console.error( 'Unexpected response, no poll content from API', json )

		}

	}
	else {

		// POPULATE list view

		// let json = await fetch_poll_list()
		
		// content.store_location_polls( json )

		// populate_location_polls_list( json )
		


		// Go to code entry form

		to_code_form()

	}

}



let to_list_view = async () => { 


	if ( controller.SESSION_LOCATION ) {

		nodes.home.block_1.classList.remove('active')

		let json = await fetch_poll_list()
			
		content.store_location_polls( json )

		populate_location_polls_list( json )

	}


}


let begin_alt_handler = async () => {

	let valid_pair = controller.validate_poll_location()

	if ( valid_pair ) {

		let json = await fetch_content()
	
		// ASSUME valid if not empty		
		if ( Object.keys( json ).length > 0 ) {

			content.store_poll_content( json )

			let data = await fetch_data( json )

			content.store_poll_data( data )

			navigation.link_click( null, 'poll/' + controller.POLL_ID )

		}
		else {

			console.error( 'Unexpected response, no poll content from API' )

		}

	}
	else {

		console.log( 'Unexpected mismatch, location has been specified, and user should be only able to pick from valid polls' )

	}

}



let fetch_poll_list = async () => {

	let url = process.env.CMS_HOST + process.env.CMS_POLLS_BY_LOCATION
	let body = JSON.stringify( { location: controller.SESSION_LOCATION  } )
	let response = await fetch( 
		url, 
		{ 
			method: 'POST',
			mode: 'cors',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: body
		}
	)

	let json = await response.json()

	return json

}


let fetch_content = async () => {

	let url = process.env.CMS_HOST + process.env.CMS_POLL_CONTENT
	let body = JSON.stringify( { poll_id: controller.POLL_ID  } )
	let response = await fetch( 
		url, 
		{ 
			method: 'POST',
			mode: 'cors',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: body
		}
	)

	let json = await response.json()

	return json

}


let fetch_data = async ( content ) => {

	let qids = []
	content.QUESTIONS.forEach( q => { qids.push( q.id ) } )

	let url = process.env.CMS_HOST + process.env.CMS_DATA_API
	let body = JSON.stringify( { question_ids: qids } )
	let response = await fetch( 
		url, 
		{ 
			method: 'POST',
			mode: 'cors',
			headers: {
				// 'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: body
		}
	)

	let json = await response.json()
	
	return json

}







export let populate_location_polls_list = ( data ) => {

	if ( Object.keys( data ).length > 0 ) {

		nodes.home.list_wrap.innerHTML = list_template( data )
		poll_buttons = utils.qsa( '.section.home .poll-link' )

	}
	else {

		nodes.home.list_wrap.innerHTML = empty_list_template( content.all )
		poll_buttons = []

	}

	let loc_name = content.all.GENERAL.LOCATIONS[ controller.SESSION_LOCATION ].name
	nodes.home.list_label.innerHTML = loc_name

	nodes.home.block_1.classList.remove('active')
	nodes.home.block_2.classList.add('active')
	nodes.home.block_3.classList.remove('active')

}





let to_code_form = () => {


	let loc_name = content.all.GENERAL.LOCATIONS[ controller.SESSION_LOCATION ].name
	nodes.home.code_list_label.innerHTML = loc_name

	nodes.home.block_1.classList.remove('active')
	nodes.home.block_2.classList.remove('active')
	nodes.home.block_3.classList.add('active')

}


let focus_input = () => {

	nodes.home.code_input.focus()

}


let entry_handler = async () => {


	let val = nodes.home.code_input.value

	val = val.toUpperCase()

	nodes.home.code_input.value = val


	if ( val.length === 6 ) {

		let url = process.env.CMS_HOST + process.env.CMS_POLL_EXISTS_API
		let body = JSON.stringify( { poll_id: val, location: controller.SESSION_LOCATION } )
		let response = await fetch( 
			url, 
			{ 
				method: 'POST', 
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: body
			}
		)

		let json = await response.json()

		if ( json.valid === true ) {
			
			controller.set_poll( json )
			nodes.home.code_begin_button_alt.classList.add( 'ready' )
			nodes.home.code_input.classList.add( 'valid' )

		}
		else {

			nodes.home.code_begin_button_alt.classList.remove( 'ready' )
			nodes.home.code_input.classList.add( 'invalid' )

		}

	}
	else {

		nodes.home.code_input.classList.remove( 'valid' )
		nodes.home.code_input.classList.remove( 'invalid' )
		nodes.home.code_begin_button_alt.classList.remove( 'ready' )

	}



}















let back_handler = ( event ) => {

	controller.set_poll( null )

	nodes.home.code_input.value = ''

	entry_handler()

	nodes.home.block_1.classList.add('active')
	nodes.home.block_2.classList.remove('active')
	nodes.home.block_3.classList.remove('active')

	validate_list_begin_button()

}


let list_handler = ( event ) => {

	let e = event
	if ( !e.path ) {
		e.path = [ event.target ]
	}

	let el = utils.walking_class_test( e.path[ 0 ], 'poll-link' )

	if ( el && el.dataset && el.dataset['pollid'] ) {
		
		clear_poll_selection()
		el.classList.add( 'active' )

		let pid = el.dataset['pollid']
		let meta = {
			valid: true,
			id: pid,
			location: content.all.LOCATION_POLLS[ pid ].location
		}
		controller.set_poll( meta )

		validate_list_begin_button()

	}

}


let validate_list_begin_button = () => {

	if ( controller.get_poll() ) {

		nodes.home.begin_button_alt.classList.add( 'ready' )

	}
	else {

		nodes.home.begin_button_alt.classList.remove( 'ready' )
	}
	
}


let clear_poll_selection = () => {

	poll_buttons.forEach( el => {

		el.classList.remove('active')

	} ) 

}


let build_scrollable_list = () => {

	let d = Draggable.create(
		nodes.home.list_wrap,
		{
			type: 'scroll',
			inertia: true
		}
	)


}





// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Register Page

new Page( {

	path: 'home',

	instance: false,

	template: template,

	get_data: ( query ) => {
		return content.all
	},

	parent: false,

	on_load: build

} )









// USED when an non-top level page is navigated to directly

export let pre_build = () => {

	if ( !status.init ) {

		controller.sync_window_size_state()

		nodes_build( 'home' )

		world.build_world()

	}

	status.init = true

}