import moment from 'moment-timezone'

import * as controller from './../app/controller'
import * as content from './content'
import * as utils from './utils'
import colors from './colors'



export let build = () => {

	let tz = content.all.GENERAL.LOCATIONS[ controller.SESSION_LOCATION ].tz
	let now = new Date()
	let zoned = moment( now ).tz( tz )
	let start = moment( '1970-01-01' ).tz( tz )
	let diff = Math.floor( ( zoned - start ) / ( 1000 * 60 * 60 * 24 ) )

	palette = []
	base.forEach( ( c, i ) => {
		let j = ( ( i + diff ) % base.length )
		palette.push( base[ j ] )
	} )

}


export let palette = []

let base = [ 'purple', 'blue', 'green', 'orange', 'red'  ]



export let get = ( color, shade, question_type, range_max ) => {

	if ( typeof color === 'string' ) {

		let key = `${ color }_${ shade }`
		if ( colors.brand[ key ] ) {
			return colors.brand[ key ]
		}
		else {
			console.log('Palette: Error, cant find color', color, shade )
			return '#FF0000'
		}

	}
	else if ( typeof color === 'number' ) {

		let index = 0


		// - - - BINARY & RANGE, use exact values from palette

		if ( question_type === 'MULTIPLECHOICE' || question_type === 'BINARY' ) {
			
			// snap to value in case js math being weird
			index = Math.round( color )
		
			// binary uses colors that are one step away, so more contrast
			if ( question_type === 'BINARY' ) index = Math.round( color * 2 )
		
			// lookup color in palette and return
			let key = `${ palette[ index ] }_${ shade }`
			if ( colors.brand[ key ] ) {
				return colors.brand[ key ]
			}

			// error
			else {
				console.log('Palette: Error, cant find color', color, shade )
				return '#FF0000'
			}

		}


		// - - - RANGE interp colors between 3-pole gradient

		else if ( question_type === 'RANGE' ) {

			let blend = 0

			if ( range_max === 100 ) {
				blend = utils.clamp( ( color / 100 ) * 2, 0, 2 )
			}
			else {
				blend = utils.clamp( ( color / 9 ) * 2, 0, 2 )
			}

			let color_a = ( blend < 1 ) 
				? utils.hex_to_rgb( colors.brand[ `${ palette[ 0 ] }_${ shade }` ] )
				: utils.hex_to_rgb( colors.brand[ `${ palette[ 1 ] }_${ shade }` ] )

			let color_b = ( blend < 1 ) 
				? utils.hex_to_rgb( colors.brand[ `${ palette[ 1 ] }_${ shade }` ] )
				: utils.hex_to_rgb( colors.brand[ `${ palette[ 2 ] }_${ shade }` ] )


			blend = ( blend < 1 )
				? blend
				: blend - 1

			let color_c = {
				r: Math.round( utils.mix( blend, color_a.r, color_b.r ) ),
				g: Math.round( utils.mix( blend, color_a.g, color_b.g ) ),
				b: Math.round( utils.mix( blend, color_a.b, color_b.b ) )
			}

			let hex_c = utils.rgb_to_hex( color_c )

			return hex_c

		}


		// - - - QUESTION type error

		else {
			console.log('Palette: Error, invalid question_type', question_type )
			return '#FF0000'
		}

	}


}