export default {

	black: 			"#000",
	white: 			"#fff",


	brand: {
		offblack: 		"#1c2b33",
		darkgrey: 		"#323232",
		medgrey: 		"#bbb",
		offwhite: 		"#cbd2d9",
		slate: 			"#69798b",

		red: 			"#E80B66",
		green: 			"#1FCC7A",
		blue: 			"#4B19D8",
		salmon: 		"#f5a490",

		purple_dark:	"#3e2d86",
		purple_light:	"#8e95ca",
		blue_dark:		"#005b94",
		blue_light:		"#5bc4f1",
		green_dark:		"#005a42",
		green_light:	"#4da458",
		orange_dark:	"#ca3112",
		orange_light:	"#f29111",
		red_dark:		"#790a1d",
		red_light:		"#dc3746"
	},

	bg: {
		base: 			"#1C2B33",
		purple: 		"#34326b",
		blue: 			"#0a5d87",
		green: 			"#095942",
		orange: 		"#9e4d38",
		red: 			"#6d0f23"
	}

}