import { gsap, CustomEase, SteppedEase, Draggable, DrawSVGPlugin, Power2, Power1, Power0, SplitText, InertiaPlugin } from 'gsap'
gsap.registerPlugin( CustomEase, SteppedEase, DrawSVGPlugin, Draggable, InertiaPlugin, SplitText )

import * as utils from 'utils'
import * as poll from './../sections/poll'
import * as content from './../components/content'
import Question from './Question'
import { nodes } from 'nodes'
import * as palette from './../components/palette'


export default class Binary extends Question {

	constructor( root_node, data ) {

		super( root_node, data )

		this.responses = data.responses

		// fetch specific nodes
		this.nodes.response_list = utils.qs( '.response-list', this.nodes.view )
		this.nodes.response_options = utils.qsa( '.response-label', this.nodes.view )
		this.nodes.slider_handle = utils.qs( '.slider-wrap .handle', this.nodes.view )
		this.nodes.slider_wrap = utils.qs( '.slider-wrap', this.nodes.view )

		this.nodes.data_bar = utils.qs('.data-bar', this.nodes.view )
		this.nodes.labels = {}
		this.nodes.sublabels = {}
		if ( this.location === 'GLOBAL' ) {
			[].concat( content.locs, [ 'SUMMARY' ] ).forEach( l => {

				this.nodes.labels[ l ] = []
				this.nodes.labels[ l ].push( utils.qs( `.labels-wrap.${ l } .oppo-blurb`, this.nodes.view ) )
				this.nodes.labels[ l ].push( utils.qs( `.labels-wrap.${ l } .same-blurb`, this.nodes.view ) )
				this.nodes.labels[ l ].push( utils.qs( `.labels-wrap.${ l } .labels-row`, this.nodes.view ) )
				
				this.nodes.sublabels[ l ] = {}
				this.nodes.sublabels[ l ].same_blurb_value = utils.qs( `.labels-wrap.${ l } .same-blurb .value`, this.nodes.view )
				this.nodes.sublabels[ l ].same_blurb_response = utils.qs( `.labels-wrap.${ l } .same-blurb .response`, this.nodes.view )
				this.nodes.sublabels[ l ].bar_same = utils.qs( `.labels-wrap.${ l } .labels-row .label.same`, this.nodes.view )
				this.nodes.sublabels[ l ].bar_oppo = utils.qs( `.labels-wrap.${ l } .labels-row .label.oppo`, this.nodes.view )
				this.nodes.sublabels[ l ].oppo_blurb_value = utils.qs( `.labels-wrap.${ l } .oppo-blurb .value`, this.nodes.view )
				this.nodes.sublabels[ l ].oppo_blurb_response = utils.qs( `.labels-wrap.${ l } .oppo-blurb .response`, this.nodes.view )
			
			} )
		}
		else {
			let l = 'SUMMARY'
			this.nodes.labels[ l ] = []
			this.nodes.labels[ l ].push( utils.qs( `.labels-wrap.${ l } .oppo-blurb`, this.nodes.view ) )
			this.nodes.labels[ l ].push( utils.qs( `.labels-wrap.${ l } .same-blurb`, this.nodes.view ) )
			this.nodes.labels[ l ].push( utils.qs( `.labels-wrap.${ l } .labels-row`, this.nodes.view ) )
			
			this.nodes.sublabels[ l ] = {}
			this.nodes.sublabels[ l ].same_blurb_value = utils.qs( `.labels-wrap.${ l } .same-blurb .value`, this.nodes.view )
			this.nodes.sublabels[ l ].same_blurb_response = utils.qs( `.labels-wrap.${ l } .same-blurb .response`, this.nodes.view )
			this.nodes.sublabels[ l ].bar_same = utils.qs( `.labels-wrap.${ l } .labels-row .label.same`, this.nodes.view )
			this.nodes.sublabels[ l ].bar_oppo = utils.qs( `.labels-wrap.${ l } .labels-row .label.oppo`, this.nodes.view )
			this.nodes.sublabels[ l ].oppo_blurb_value = utils.qs( `.labels-wrap.${ l } .oppo-blurb .value`, this.nodes.view )
			this.nodes.sublabels[ l ].oppo_blurb_response = utils.qs( `.labels-wrap.${ l } .oppo-blurb .response`, this.nodes.view )
		}

		this.nodes.all_labels = []
		for ( let loc in this.nodes.labels ) {
			this.nodes.all_labels = this.nodes.all_labels.concat( this.nodes.labels[ loc ] )
		}


		// listeners
		this.nodes.response_list.addEventListener( 'click', ( e ) => { this.response_handler( e ) } )

		this.test_cache()

	}


	build_slider() {

		if ( !this.visible ) return

		let x_min = -0.5 * ( this.nodes.slider_handle.offsetWidth - this.nodes.slider_handle.clientWidth )
		let x_max = this.nodes.slider_wrap.clientWidth + -2.0 * x_min * 2.0

		gsap.to(
			this.nodes.slider_handle,
			0.25,
			{ borderColor: "#bbbbbb" }
		)

		this.slider_x_px = 0
		this.slider_x_norm = 0

		if ( this._draggable ) {
			this.get_slider_position()
			this._draggable[ 0 ].kill()
			this._draggable[ 0 ] = null
			this._draggable = null
		}

		this.slider_x_norm = 0.5
		this.slider_x_px = 45

		gsap.set( 
			this.nodes.slider_handle,
			{ x: this.slider_x_px }
		)

		this._draggable = Draggable.create(
			this.nodes.slider_handle,
			{
				type: "x",
				trigger: this.nodes.slider_wrap,
				bounds: {
					left: x_min,
					width: x_max 
				},
				inertia: true,
				snap: {
					x: ( val ) => {
						return this.snap_helper( val )
					}
				},
				onPress: () => {

					if ( this._scroll_to_tl ) {
						this._scroll_to_tl.pause()
						this._scroll_to_tl.kill()
						this._scroll_to_tl = null
					}

					let rect = this.nodes.slider_wrap.getBoundingClientRect()
					let x = this._draggable[ 0 ].pointerX - rect.left

					x = utils.clamp( x / rect.width, 0, 1 )

					let i = Math.round( x )

					this.scroll_to( i )

				},
				onDragStart: ( e ) => {

					this.drag_start_pos = this._draggable[ 0 ].x

				},
				onMove: () => {

					if ( this._scroll_to_tl ) {
						this._scroll_to_tl.pause()
						this._scroll_to_tl.kill()
						this._scroll_to_tl = null
					}

					this.get_slider_position()
					this.sync_slider_ui()
				},
				onThrowUpdate: () => {
					this.get_slider_position()
					this.sync_slider_ui()
				},
				minDuration: 0.15,
				maxDuration: 0.15,
				edgeResistance: 1,
				throwResistance: 3000
			}
		)

		this._draggable[ 0 ].applyBounds()

		this._draggable[ 0 ].update()

		this.get_slider_position()
		this.sync_slider_ui( true )

	}

	scroll_to( index ) {

		let x = index
			? this._draggable[ 0 ].maxX
			: this._draggable[ 0 ].minX

		this.select_response( index )

		this.sync_slider_ui( undefined, true )

		this._scroll_to_tl = gsap.to( 
			this._draggable[ 0 ].target,
			0.15,
			{
				x: x,
				onUpdate: () => {
					this._draggable[ 0 ].update()
					this.get_slider_position()
					this.sync_slider_ui()
				}
			}
		)


	}

	get_slider_position() {

		if ( this._draggable ) {
			let x = this._draggable[ 0 ].x
			let min_x = this._draggable[ 0 ].minX
			let max_x = this._draggable[ 0 ].maxX
			let norm_x = ( x - min_x ) / ( max_x - min_x )
			this.slider_x_px = x
			this.slider_x_norm = norm_x
		}

	}


	snap_helper( value ) {

		let norm =
			( value - this._draggable[ 0 ].minX ) /
			( this._draggable[ 0 ].maxX - this._draggable[ 0 ].minX )

		norm = utils.clamp( norm, 0, 1 )

		let index = Math.round( norm )

		// - - - BUMP, give some extra oomph to a small but intentional swipe

		let bump_threshold = 6

		if ( 
			Math.abs( this.drag_start_pos - value ) > bump_threshold 
			&&
			index === this.response_index
		) {
			
			let dir = ( value > this.drag_start_pos )
				? 1
				: -1

			index += dir

			index = utils.clamp( index, 0, this.responses.length - 1 )

		}

		let x = index
			? this._draggable[ 0 ].maxX
			: this._draggable[ 0 ].minX
		

		return ( this.response_index === null ) ? value : x
		
	}


	sync_slider_ui( block_paint, force ) {

		let i =  Math.round( this.slider_x_norm )

		if ( ( !block_paint && i !== this.response_index ) || force ) {

			this.select_response( i )

			let c = palette.get( i, 'light', this.type, this.range_max )

			gsap.to(
				this.nodes.slider_handle,
				0.25,
				{ borderColor: c }
			)

		}

	}



	response_handler( event ) {

		let e = utils.norm_click( event )
		let el = utils.walking_class_test( e.path[ 0 ], 'response-label' )
		
		if ( el ) {

			let i = Number( el.dataset['index'] )

			this.scroll_to( i )

		}

	}


	select_response( index ) {

		if ( !( index >= 0 && index < this.responses.length ) ) {
			console.log( 'Error: cannot select, index out of range', index )
			return
		}

		this.nodes.response_options.forEach( el => {
			el.classList.remove( 'selected' )
		} )

		if ( this.nodes.response_options.length > index && index >= 0  ) {
			let el = this.nodes.response_options[ index ]
			el.classList.add( 'selected' )
		}

		this.response_index = index

		poll.sync_submit_button()

	}


	reset_response( force ) {

		if ( force || ( this.response_index !== null && !this.submitted ) ) {

			this.response_index = null

			this.nodes.response_options.forEach( el => {
				el.classList.remove( 'selected' )
			} )

			poll.sync_submit_button()

		}

	}


	reset_dataviz() {

		this.loc_in_view = 'SUMMARY'

		// this.apply_loc_view_change()

	}


	update_dataviz() {

		if ( !this.submitted ) return

		if ( this.location === 'GLOBAL' ) {

			for ( let loc in this.data ) {
				let d = this.data[ loc ]
				let t = this.data[ loc ].total
				let oppo_i = this.response_index === 0 ? 1 : 0
				this.nodes.sublabels[ loc ].same_blurb_value.innerHTML = this.data[ loc ].totals[ this.response_index ]
				this.nodes.sublabels[ loc ].same_blurb_response.innerHTML = this.responses[ this.response_index ]
				let same = t > 0 ? Math.round( 100 * this.data[ loc ].totals[ this.response_index ] / t ) + '%' : 0
				let oppo = t > 0 ? Math.round( 100 * this.data[ loc ].totals[ oppo_i ] / t ) + '%' : 0
				this.nodes.sublabels[ loc ].bar_same.innerHTML = same
				this.nodes.sublabels[ loc ].bar_oppo.innerHTML = oppo
				this.nodes.sublabels[ loc ].oppo_blurb_value.innerHTML = this.data[ loc ].totals[ oppo_i ]
				this.nodes.sublabels[ loc ].oppo_blurb_response.innerHTML = this.responses[ oppo_i ]
			}

		}
		else {

			let loc = 'SUMMARY'
			let d = this.data[ loc ]
			let t = this.data[ loc ].total
			let oppo_i = this.response_index === 0 ? 1 : 0
			this.nodes.sublabels[ loc ].same_blurb_value.innerHTML = this.data[ loc ].totals[ this.response_index ]
			this.nodes.sublabels[ loc ].same_blurb_response.innerHTML = this.responses[ this.response_index ]
			let same = t > 0 ? Math.round( 100 * this.data[ loc ].totals[ this.response_index ] / t ) + '%' : 0
			let oppo = t > 0 ? Math.round( 100 * this.data[ loc ].totals[ oppo_i ] / t ) + '%' : 0
			this.nodes.sublabels[ loc ].bar_same.innerHTML = same
			this.nodes.sublabels[ loc ].bar_oppo.innerHTML = oppo
			this.nodes.sublabels[ loc ].oppo_blurb_value.innerHTML = this.data[ loc ].totals[ oppo_i ]
			this.nodes.sublabels[ loc ].oppo_blurb_response.innerHTML = this.responses[ oppo_i ]

		}

		this.tween_updated_data()

	}

	
	resize() {
		
	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - UPDATE LOOP

	update() {

		gsap.set( 
			[ this.bg_rings.dark.node, this.bg_rings.light.node, this.fg_rings.main.node ], 
			{ x: poll.submit_position_cache.x, y: poll.submit_position_cache.y }
		)

		this.bg_rings.dark.update()
		this.bg_rings.light.update()
		this.fg_rings.main.update()

	}



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - HELPERS

	split_question() {

		if ( this.question_split ) this.question_split.revert()

		this.question_split = new SplitText( 
			this.nodes.question, 
			{ type: 'lines', linesClass: 'line' } 
		)

		this.question_split.lines.forEach( line => {
			// console.log( line )
			line.innerHTML = `<div class="line-inner">${ line.innerHTML }</div>`
			line.style.overflow = 'hidden'
		} )

		this.question_split_inners = utils.qsa( '.question .line-inner', this.nodes.view )

	}

	split_response() {

		if ( this.response_split ) this.response_split.revert()

		this.response_split = new SplitText( 
			this.nodes.submit_value, 
			{ type: 'lines', linesClass: 'line' } 
		)

		this.response_split.lines.forEach( line => {
			line.innerHTML = `<div class="line-inner">${ line.innerHTML }</div>`
			line.style.overflow = 'hidden'
		} )

		this.response_split_inners = utils.qsa( '.submit-value .line-inner', this.nodes.view )

	}


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  TWEENS


	async tween_in() {

		await new Promise( resolve => {

			this.tween_in_start()

			gsap.set( this.nodes.view, { opacity: 0 } )

			this.build_slider()

			this._tween_in_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					resolve()
					if ( this.question_split ) this.question_split.revert()
					this.tween_in_complete()
					if ( this.submitted ) {
						this._show_next_button_timer = setTimeout( poll.show_next_button, 5 * 1000 )
					}
				},
				onStart: () => {
					
				}
			} )

			gsap.set(
				this.nodes.all_labels,
				{ opacity: 0 }
			)

			// Split text for question
			this.split_question()

			// TO DATA view
			if ( this.submitted ) {

				let c = palette.get( this.response_index, 'light', this.type )
				gsap.set( 
					this.nodes.data_bar, 
					{ 
						backgroundColor: c, 
						scaleX: 0,
						transformOrigin: "0% 50%"
					}
				)

				let t = ( this.data[ this.loc_in_view ].total > 0 )
					? this.data[ this.loc_in_view ].totals[ this.response_index ] / this.data[ this.loc_in_view ].total
					: 0

				this._tween_in_tl.add( gsap.to(
					this.nodes.data_bar,
					0.5,
					{
						scaleX: t,
						transformOrigin: "0% 50%"
					}
				), 0.0 )

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.view,
					0.25,
					{
						opacity: 0,
					},
					{
						opacity: 1
					}
				), 0 )

				this._tween_in_tl.add( gsap.to(
					this.nodes.labels[ this.loc_in_view ],
					0.25,
					{
						opacity: 1
					}
				), 0 )

				gsap.set( 
					this.nodes.question_small_a_wrap,
					{ opacity: 1 }
				)

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.question_small,
					0.25,
					{
						opacity: 0,
					},
					{
						opacity: 1
					}
				), 0.0 )

				gsap.set( this.bg_rings.dark.node, { scale: 1, opacity: 0, transformOrigin: "50% 50%" } )

				this._tween_in_tl.add( gsap.to(
					this.bg_rings.dark.node,
					0.5,
					{
						opacity: 1
					}
				), 0 )

			}

			// TO RESPONSE view
			else {

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.view,
					0.1,
					{
						opacity: 0,
					},
					{
						opacity: 1
					}
				), 0 )

				this._tween_in_tl.add( gsap.fromTo(
					this.question_split_inners,
					0.4,
					{
						y: "+=100%"
					},
					{
						y: "0%",
						ease: Power2.easeOut,
						stagger: 0.15
					}

				), 0.4 )

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.loc_eyebrow,
					0.2,
					{
						opacity: 0,
						y: 10
					},
					{
						opacity: 0.5,
						y: 0,
						ease: Power2.easeOut
					}
				), 0.7 )

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.divider,
					0.3,
					{
						scaleX: 0
					},
					{
						scaleX: 1,
						transformOrigin: "0% 50%",
						ease: this.wipe_ease
					}

				), 0.2 )

				this._tween_in_tl.add( gsap.fromTo(
					this.nodes.response_list,
					0.2,
					{
						opacity: 0,
					},
					{
						opacity: 1,
						stagger: 0.05
					}
				), 0.8 )

			}

			this._tween_in_tl.play()

		} )

	}


	async tween_out() {
		
		await new Promise( resolve => {

			this._tween_out_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					resolve()
					this.tween_out_complete()
				},
				onStart: () => {
					this.tween_out_start()
				}
			} )

			// FROM DATA view
			if ( this.submitted ) {

				this._tween_out_tl.add( gsap.fromTo(
					this.nodes.view,
					0.25,
					{
						opacity: 1,
					},
					{
						opacity: 0
					}
				), 0 )

				this._tween_out_tl.add( gsap.to(
					this.bg_rings.dark.node,
					0.5,
					{
						opacity: 0
					}
				), 0 )
			}

			// FROM RESPONSE view
			else {

				this._tween_out_tl.add( gsap.fromTo(
					this.nodes.view,
					0.25,
					{
						opacity: 1,
					},
					{
						opacity: 0
					}
				), 0 )	

			}

			this._tween_out_tl.play()

		} )

	}




	async tween_submit() {

		await new Promise( resolve => {

			gsap.set( this.nodes.submit_wrap, { opacity: 0 } )
		
			this.nodes.submit_wrap.classList.add( 'visible' )

			this.split_response()

			this._submit_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					this.nodes.response_wrap.classList.remove( 'visible' )
					this._submit_tl.kill()
					this._submit_tl = null
					resolve()
				},
				onStart: () => {
					this.set_interactive( false )
				}
			} )

			this._submit_tl.add( gsap.fromTo(
				this.nodes.response_wrap,
				0.25,
				{
					opacity: 1,
				},
				{
					opacity: 0
				}
			), 0 )

			this._submit_tl.add( gsap.fromTo(
				this.bg_rings.dark.node,
				2.0,
				{
					scale: 0,
				},
				{
					scale: 1,
					transformOrigin: "50% 50%",
					ease: this.wipe_ease
				}
			), 0 )

			this._submit_tl.add( gsap.fromTo(
				this.bg_rings.light.node,
				1.75,
				{
					scale: 0,
					opacity: 1,
				},
				{
					opacity: 0.0,
					scale: 0.75,
					transformOrigin: "50% 50%",
					ease: this.wipe_ease
				}
			), 0.2 )

			this._submit_tl.add( gsap.fromTo(
				this.fg_rings.main.node,
				1.75,
				{
					scale: 0,
					opacity: 1,
				},
				{
					opacity: 0.0,
					scale: 1.0,
					transformOrigin: "50% 50%",
					ease: this.wipe_ease
				}
			), 0.4 )

			this._submit_tl.add( gsap.fromTo(
				this.nodes.submit_wrap,
				0.15,
				{
					opacity: 0,
				},
				{
					opacity: 1
				}
			), 0.25 )

			this._submit_tl.add( gsap.fromTo(
				[ this.nodes.submit_label_inner ].concat( this.response_split_inners ),
				0.4,
				{
					y: "+=100%"
				},
				{
					y: "0%",
					ease: Power2.easeOut,
					stagger: 0.15
				}
			), 0.25 )

			this._submit_tl.add( gsap.fromTo(
				this.nodes.question_small,
				0.25,
				{
					opacity: 0,
				},
				{
					opacity: 1
				}
			), 0.25 )

			gsap.set( 
				this.nodes.question_small_a_wrap,
				{ opacity: 0 }
			)

			this._submit_tl.play()

		} )

	}


	async tween_data() {

		await new Promise( resolve => {

			this._data_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					this.nodes.submit_wrap.classList.remove( 'visible' )
					this._data_tl.kill()
					this._data_tl = null
					this.set_interactive( true )
					this._show_next_button_timer = setTimeout( poll.show_next_button, 5 * 1000 )
					resolve()
				},
				onStart: () => {
					this.nodes.data_wrap.classList.add( 'visible' )
				}
			} )

			let c = palette.get( this.response_index, 'light', this.type )
			gsap.set( 
				this.nodes.data_bar, 
				{ 
					backgroundColor: c, 
					scaleX: 0,
					transformOrigin: "0% 50%"
				}
			)

			let t = ( this.data[ this.loc_in_view ].total > 0 )
				? this.data[ this.loc_in_view ].totals[ this.response_index ] / this.data[ this.loc_in_view ].total
				: 0
				
			this._data_tl.add( gsap.to(
				this.nodes.data_bar,
				0.5,
				{
					scaleX: t,
					transformOrigin: "0% 50%"
				}
			), 0.0 )

			this._data_tl.add( gsap.fromTo(
				this.nodes.submit_wrap,
				0.25,
				{
					opacity: 1,
				},
				{
					opacity: 0
				}
			), 0.0 )

			this._data_tl.add( gsap.fromTo(
				this.nodes.data_wrap,
				0.25,
				{
					opacity: 0,
				},
				{
					opacity: 1
				}
			), 0.25 )

			this._data_tl.add( gsap.to(
				this.nodes.labels[ this.loc_in_view ],
				0.25,
				{
					opacity: 1
				}
			), 0.25 )

			this._data_tl.add( gsap.fromTo(
				this.nodes.question_small_a_wrap,
				0.25,
				{
					opacity: 0,
				},
				{
					opacity: 1
				}
			), 0.25 )


			this._data_tl.play()

		} )

	}


	to_loc_chain() {

		if ( this.to_loc_chain_dirty ) {

			this._loc_tl.pause()
			this._loc_tl.kill()
			this._loc_tl = null

			this.tween_loc( this.to_loc_chain_dirty )

			this.to_loc_chain_dirty = false

		}

	}

	async tween_loc( loc ) {

		await new Promise( resolve => {

			if ( this._loc_tl ) {
				this.to_loc_chain_dirty = loc
				return
			}
			
			if ( !(
				this.nodes.labels[ this.loc_in_view ] && 
				this.nodes.labels[ this.loc_in_view ].length > 0  && 
				this.nodes.labels[ loc ] && 
				this.nodes.labels[ loc ].length > 0
			) ) {
				console.log( 'Error: cannot tween to or from invalid location slugs', this.loc_in_view, loc, this.nodes.labels )
				return
			}

			if ( loc === this.loc_in_view ) {
				return
			}

			this._loc_tl = gsap.timeline( {
				paused: true,
				onComplete: () => {
					if ( this.to_loc_chain_dirty ) {

						this.to_loc_chain()

					}
					else {
						resolve()
						this._loc_tl.pause()
						this._loc_tl.kill()
						this._loc_tl = null
					}
				},
				onStart: () => {

				}
			} )

			let t = ( this.data[ loc ].total > 0 )
				? this.data[ loc ].totals[ this.response_index ] / this.data[ loc ].total
				: 0

			this._loc_tl.add( gsap.to(
				this.nodes.data_bar,
				0.5,
				{
					scaleX: t,
					transformOrigin: "0% 50%"
				}
			), 0.0 )

			this._loc_tl.add( gsap.to(
				this.nodes.labels[ this.loc_in_view ],
				0.25,
				{ opacity: 0 }
			), 0 )

			this._loc_tl.add( gsap.to(
				this.nodes.labels[ loc ],
				0.25,
				{ opacity: 1 }
			), 0.25 )

			this.loc_in_view = loc

			this._loc_tl.play()

		} )

	}



	tween_updated_data() {

		if ( !this.submitted && !this.visible ) {
			return
		}

		if ( this._data_update_tl ) {
			this._data_update_tl.pause()
			this._data_update_tl.kill()
			this._data_update_tl = null
		}

		this._data_update_tl = gsap.timeline( {
			paused: true,
			onComplete: () => {

			},
			onStart: () => {

			}
		} )

		let t = ( this.data[ this.loc_in_view ].total > 0 )
			? this.data[ this.loc_in_view ].totals[ this.response_index ] / this.data[ this.loc_in_view ].total
			: 0
				
		this._data_update_tl.add( gsap.to(
			this.nodes.data_bar,
			0.25,
			{
				scaleX: t,
				transformOrigin: "0% 50%"
			}
		), 0.0 )
		

		this._data_update_tl.play()

	}

}